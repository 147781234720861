import React from "react";

// STYLES
import './MobileNavbar.css';

// ASSETS
import User from '../../../assets/images/home/user-logo.svg';
import Logo from '../../../assets/images/logo/notext-logo.svg';

// COMPONENTS
import CartLink from "../../functional_components/cartLink/CartLink";
import CartSiderMenu from "../../functional_components/cartSiderMenu/CartSiderMenu";

// REDUX
import { useDispatch, connect } from "react-redux";
import { setCart } from "../../../redux/ducks/Cart";

import { Image, Typography } from "antd";
import { ENVIRONMENT } from "../../../utils/properties";
import { isEmpty } from "lodash";


const MobileNavbar = (props) => {

  const { Link } = Typography;
  const dispatch = useDispatch();

  const closeSiderMenu = () => {
    dispatch(setCart(false))
  }

  const getHomeLink = () => {
    let userType = "B2C";
    if (props.userInfoDuck.userInfo && !isEmpty(props.userInfoDuck.userInfo)) {
      if (props.userInfoDuck.userInfo.type !== "B2C") {
        userType = "B2B";
      }
    }

    let link = `${ENVIRONMENT.ROUTING.BASE_URL}`;

    if (userType !== "B2C") {
      link = `${ENVIRONMENT.ROUTING.BASE_URL}B2B/home`;
    }

    return link
  }

  return (
    <>
      <div className={'mobile-navbar-container'}>
        {
          !props.checkout &&
          <Link
            href={`${ENVIRONMENT.ROUTING.BASE_URL}profile`}
            className={'mobile-navbar-button-container'}
          >
            <Image
              src={User}
              alt={'User'}
              preview={false}
            />
          </Link>
        }
        <Link
          href={getHomeLink()}
          className={'mobile-navbar-button-container'}
        >
          <Image
            src={Logo}
            alt={'Logo'}
            preview={false}
          />
        </Link>
        {
          !props.checkout &&
          <CartLink />
        }
      </div>
      <CartSiderMenu
        callback={closeSiderMenu}
      />
    </>
  )
}

const mapStateToProps = state => ({
  userInfoDuck: state.userInfoDuck
})

export default connect(mapStateToProps)(MobileNavbar)