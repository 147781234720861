const SET_PRODUCTSLIST = 'SET_PRODUCTSLIST'
const INIT_PRODUCTSLIST = 'INIT_PRODUCTSLIST'

export function setProductsList(value) {
  return {
    type: SET_PRODUCTSLIST,
    payload: {
      productsList: value
    }
  };
}

export function initProductsList() {
  return {
    type: INIT_PRODUCTSLIST,
    payload: {
      productsList: {}
    }
  }
}

const INIT_STATE = {
  productsList: {}
}

export default function productsListDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_PRODUCTSLIST:
      newState.productsList = action.payload.productsList;
      break;
    case INIT_PRODUCTSLIST:
      newState.productsList = {};
      break;
    default:
      break;
  }
  return newState;
}