import React, { useEffect, useState } from "react";

// STYLES
import './MainLayout.css'

// COMPONENTS
import HeaderHome from "../../components/functional_components/headerHome/HeaderHome";
import FooterHome from "../../components/functional_components/footerHome/FooterHome";
import Newsletter from "../../components/hooks_components/newsletter/Newsletter";
import MobileNavbar from "../../components/hooks_components/mobileNavbar/MobileNavbar";
import HeaderFlightSearch from "../../components/hooks_components/headerFlightSearch/HeaderFlightSearch";
import HomeServiceInfo from "../../components/functional_components/homeServiceInfo/HomeServiceInfo";
import Loading from "../../screens/Loading/Loading";

// REDUX
import { useDispatch, connect } from "react-redux";
import { doSearchAirports, doSearchAirlines, getProductsList, getHomeData, getNewsletterData, getFaqsData } from "../../redux/actions/actions";

import { Outlet } from "react-router";
import { Layout, Typography, Col } from "antd";
import { get } from "lodash";
import { setLocale } from "../../utils/utilities";

const MainLayout = (props) => {

  const { Header, Content, Footer } = Layout;
  const { Title } = Typography;
  const dispatch = useDispatch();

  const [state, setState] = useState({
    loaded: false,
    locale: null
  })

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    if (Object.keys(props.airportsDuck.airports).length === 0) {
      await doSearchAirports(dispatch, { lang: props.languageDuck.currentLanguage.toLowerCase() });
    }
    if (Object.keys(props.airlinesDuck.airlines).length === 0) {
      await doSearchAirlines(dispatch);
    }
    if (Object.keys(props.productsListDuck.productsList).length === 0) {
      await getProductsList(dispatch);
    }
    if (Object.keys(props.homeDataDuck.homeData).length === 0) {
      await getHomeData(dispatch, "home");
    }
    if (Object.keys(props.newsletterDataDuck.newsletterData).length === 0) {
      await getNewsletterData(dispatch, "newsletter");
    }

    if (Object.keys(props.faqsDuck.faqs).length === 0) {
      await getFaqsData(dispatch);
    }

    let locale = setLocale(props.languageDuck.currentLanguage);

    setState({
      ...state,
      loaded: true,
      locale: locale
    })
  }

  const getComponentData = (id) => {
    let result;
    props.homeDataDuck.homeData.data.components.map((component) => {
      if (component.id === id) {
        if (component.type === "image" || component.type === "link") {
          return result = component.value;
        }
        component.translations.map((translation) => {
          if (translation.lang === props.languageDuck.currentLanguage.toLowerCase()) {
            result = translation.text;
          }
        })
      }
    })
    return result;
  }

  return (
    <>
      <Layout className={'main-layout-container'}>
        {state.loaded &&
          <>
            <Layout className='min-h-100vh'>
              <Header
                className={'home-header'}
                style={{ backgroundImage: `url(${getComponentData("hero")})` }}
              >
                <HeaderHome />
                <div className={'home-header-image-mask'} />
                <HeaderFlightSearch locale={state.locale} />
                <Title className={'home-header-title'}>{getComponentData("title")}</Title>
                <Col className={'home-service-info-responsive-container-desktop'}>
                  {
                    get(props.productsListDuck.productsList, "data", []).map((product, index) => {
                      if (product.b2c) {
                        return (
                          <div key={index}>
                            <HomeServiceInfo
                              code={product.code}
                              title={product.name}
                              badge={product.iconImage}
                              description={product.introDescription}
                            />
                          </div>
                        )
                      }
                    })
                  }
                </Col>
              </Header>

              <MobileNavbar />

              <Content className={'home-content-container'}>
                <Outlet />
              </Content>
            </Layout>

            <Footer className={'home-footer'}>
              <Newsletter />
              <FooterHome />
            </Footer>
          </>
        }
        {
          !state.loaded &&
          <Loading />
        }
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  airportsDuck: state.airportsDuck,
  productsListDuck: state.productsListDuck,
  airlinesDuck: state.airlinesDuck,
  languageDuck: state.languageDuck,
  cartDuck: state.cartDuck,
  homeDataDuck: state.homeDataDuck,
  newsletterDataDuck: state.newsletterDataDuck,
  faqsDuck: state.faqsDuck
})

export default connect(mapStateToProps)(MainLayout)