module.exports = {
  DEPLOY_ENV_NAME: "PRODUCTION",
  API_REST_BE: "https://shop.sostravel.com/api/",
  PROVIDER_BASE_URL: "https://shop.sostravel.com/",
  ROUTING: {
    SIGNIN_URL: "/signin",
    SIGNUP_URL: "/signup",
    BASE_URL: "/",
    B2B_HOME_URL: "/B2B/home",
  },
  CDN_BASE_PATH: "https://dev-sostravel-media.azureedge.net/media",
};
