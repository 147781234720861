import React, { useRef } from "react";

// STYLES
import "./HowToCarousel.css";

// COMPONENTS
import HowToCarouselCard from "../howToCarouselCard/HowToCarouselCard";

// REDUX
import { connect } from "react-redux";

import { Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { getValueFromLang } from "../../../utils/utilities";

const HowToCarousel = (props) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const ref = useRef();

  let isDown = false;
  let startX;
  let scrollLeft;

  const onMouseDown = (e) => {
    isDown = true;
    ref.current.classList.add("home-service-carousel-active");
    startX = e.pageX - ref.current.offsetLeft;
    scrollLeft = ref.current.scrollLeft;
  };

  const notScrolling = () => {
    isDown = false;
    ref.current.classList.remove("home-service-carousel-active");
  };

  const onMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = x - startX;
    ref.current.scrollLeft = scrollLeft - walk;
  };

  const howToList = () => {
    let list = [];
    if (props.productDetail === "TravelInsurance") {
      for (let i = 0; i < 4; i++) {
        let title;
        let description;
        let image;
        props.productsDataDuck.productsData.healthtravelData.data.components.map(
          (component) => {
            if (component.id === `howTo${i}Title`) {
              title = getValueFromLang(
                component.translations,
                props.languageDuck.currentLanguage,
                true
              );
            }
            if (component.id === `howTo${i}Description`) {
              description = getValueFromLang(
                component.translations,
                props.languageDuck.currentLanguage,
                true
              );
            }
            if (component.id === `howTo${i}Image`) {
              image = component.value;
            }
          }
        );

        if (image && title && description) {
          list.push(
            <div key={i}>
              <HowToCarouselCard
                step={title}
                description={description}
                src={image}
                travelInsurance
              />
            </div>
          );
        }
      }
    } else {
      props.productsListDuck.productsList.data.map((product) => {
        if (product.code === props.productDetail) {
          product.howTo.map((how) => {
            if (how.image && how.title && how.description) {
              list.push(
                <div key={how.index}>
                  <HowToCarouselCard
                    step={getValueFromLang(
                      how.title,
                      props.languageDuck.currentLanguage
                    )}
                    description={getValueFromLang(
                      how.description,
                      props.languageDuck.currentLanguage
                    )}
                    src={how.image}
                    whiteTitle={
                      props.code !== "TravelInsurance" && !props.thankyou
                    }
                  />
                </div>
              );
            }
          });
        }
      });
    }
    list.sort(function (a, b) {
      return a.key - b.key;
    });
    return list;
  };

  return (
    <Col className={"thank-you-page-service-container"}>
      {props.thankyou && (
        <>
          <Text>{props.productDetail}</Text>
          <div className={"thank-you-page-h-line"} />
        </>
      )}

      <Text
        className={"how-to-carousel-title"}
        style={props.thankyou ? { fontSize: "15px" } : { fontSize: "18px" }}
      >
        {t(`product.howTo`)}
      </Text>
      <div
        className={
          props.productDetail === "TravelInsurance"
            ? "how-to-carousel-container-travel-insurance how-to-carousel-container"
            : "how-to-carousel-container"
        }
        onMouseDown={onMouseDown}
        onMouseUp={notScrolling}
        onMouseLeave={notScrolling}
        onMouseMove={onMouseMove}
        ref={ref}
      >
        {howToList()}
      </div>
    </Col>
  );
};

const mapStateToProps = (state) => ({
  productsListDuck: state.productsListDuck,
  productsDataDuck: state.productsDataDuck,
  languageDuck: state.languageDuck,
});

export default connect(mapStateToProps)(HowToCarousel);
