import React from "react";

// STYLES
import "../checkoutServicesBox/CheckoutServicesBox.css";

import { useTranslation } from "react-i18next";
import { Col, Input } from "antd";

const ReceiptForm = (props) => {

  const { t } = useTranslation();

  return (
    <Col>
      <div className={"checkout-services-box-receipt-title"}>{t("checkout.invoiceRequestData")}</div>
      <Col className={"checkout-user-data-input-container"}>
        <label>{t("checkout.piva")}</label>
        <Input
          id={"cdfOrPiva"}
          placeholder={"P.IVA / Codice Fiscale"}
          onChange={props.handleChange}
          onFocus={props.removeErrorMessage}
          autoComplete={"off"}
        />
      </Col>
      <Col className={"checkout-user-data-input-container"}>
        <label>{t("checkout.sdi")} ({t("general.optional")})</label>
        <Input
          id={"sdi"}
          placeholder={"SDI"}
          onChange={props.handleChange}
          onFocus={props.removeErrorMessage}
          autoComplete={"off"}
        />
      </Col>
    </Col>
  )
}

export default ReceiptForm