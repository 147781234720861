import { post, get, deleteApi } from './GenericServices';

const Api = {
  login: (data) => {
    return post('userauth/auth/login-email', data);
  },
  loginPhone: (data) => {
    return post('userauth/auth/login-phone', data);
  },
  signup: (data) => {
    return post('userauth/auth/signup-email', data);
  },
  OTP: (data) => {
    return post('userauth/auth/confirm-token', data);
  },
  updateData: (data) => {
    return post('userauth/user/profile', data);
  },
  forgotPsw: (data) => {
    return post('userauth/auth/reset-password', data);
  },
  resetPsw: (data) => {
    return post('userauth/auth/confirm-reset-password', data);
  },
  searchFlight: (data) => {
    return get('integration/search/flights', data, false);
  },
  searchAirports: (data) => {
    return get('integration/search/airports', data, false);
  },
  searchAirlines: () => {
    return get('integration/search/airlines', {}, false);
  },
  searchLounge: (data) => {
    return get('integration/search/viplounge', data, false);
  },
  pageData: (data) => {
    return get(`settings/view-page/${data}`, {}, false);
  },
  userData: () => {
    return get('userauth/user/info');
  },
  userUploadPhoto: (data) => {
    return post('userauth/user/upload-photo', data);
  },
  userOrders: (data) => {
    return get('payment/report/order-history', {...data, allPages: true });
  },
  userPromo: (data) => {
    return get(`sospromotions/v5/promotions/listByUser`, data);
  },
  paymentToken: (data) => {
    return get(`payment/checkout/token/${data}`);
  },
  payment: (data) => {
    return post('payment/checkout/process-order', data);
  },
  productsList: (isB2B) => {
    return get('products/view/settings', {}, !!isB2B);
  },
  b2bPaymentMethods: () => {
    return get('payment/payment-method');
  },
  addCreditCard: (data) => {
    return post('payment/payment-method', data)
  },
  deleteCreditCard: (data) => {
    return deleteApi('payment/payment-method', data)
  },
  activatePromo: (data) => {
    return post('sospromotions/v5/promotions/activate', data)
  },
  calculatePromo: (data) => {
    return post('payment/promotions/calculate', data)
  },
  downloadReceipt: (id) => {
    return get(`settings/storage-file/user/sostravel_${id}_CHECKOUT.pdf`, {}, true, true)
  },
  downloadItemReceipt: (id, item) => {
    return get(`settings/storage-file/user/sostravel_${id}_${item}.pdf`, {}, true, true)
  }
};

// false è quando alla get non serve il token

export default Api;