import React, { useState, useEffect } from "react";

// STYLES
import "./HomeServiceCarouselCard.css";

// COMPONENTS
import HomeServiceContent from "../homeServiceContent/HomeServiceContent";

// ASSETS
import Arrow from "../../../assets/images/homeServicesCarousel/left-arrow.svg";
import CheckIconGreen from "../../../assets/images/checkout/check-icon-green.svg";

// REDUX
import { setServices } from "../../../redux/ducks/Services";
import { useDispatch, connect } from "react-redux";

import { Image, Typography, Col, Button, Row, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../../utils/properties";
import { formatValue } from "../../../utils/utilities";

const HomeServiceCarouselCard = (props) => {
  const [state, setState] = useState({
    servicesCount: 0,
  });

  useEffect(() => {
    initialServiceCount();
  }, [props.servicesDuck]);

  const initialServiceCount = () => {
    let newServiceCount = 0;
    if (props.servicesDuck.services.length > 0) {
      props.servicesDuck.services.map((service) => {
        if (service.name === props.cardCode) {
          newServiceCount = service.value;
          setState({
            ...state,
            servicesCount: newServiceCount,
          });
        }
      });
    }
    let find = false;
    props.servicesDuck.services.map((service) => {
      if (service.name === props.cardCode) {
        find = true;
      }
    });
    if (!find) {
      setState({
        servicesCount: 0,
      });
    }
  };

  const { Text } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDiscoverMore = () => {
    let path = "";
    switch (props.cardCode) {
      case "LLC":
        path = "LLC";
        break;
      case "VIPLOUNGE":
        path = "VIPLOUNGE";
        break;
      case "TLDRFM":
        path = "DrTravel";
        break;
      case "TLDRST":
        path = "DrTravel";
        break;
      default:
        path = "LLC";
        break;
    }
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}${path}-detail`);
  };

  const addItem = () => {
    let newServiceCount = state.servicesCount + 1;
    let servicesObj = props.servicesDuck.services;

    let objIndex = null;

    servicesObj.map((item, index) => {
      if (item.name === props.cardCode) {
        objIndex = index;
      }
    });

    servicesObj[objIndex].value = newServiceCount;

    setState({
      ...state,
      servicesCount: newServiceCount,
    });

    dispatch(setServices(servicesObj));
  };

  const removeItem = () => {
    let newServiceCount = state.servicesCount - 1;
    let servicesObj = props.servicesDuck.services;

    let objIndex = null;

    servicesObj.map((item, index) => {
      if (item.name === props.cardCode) {
        objIndex = index;
      }
    });

    if (newServiceCount === 0) {
      servicesObj.splice(objIndex, 1);
    } else {
      servicesObj[objIndex].value = newServiceCount;
    }

    setState({
      ...state,
      servicesCount: newServiceCount,
    });

    dispatch(setServices(servicesObj));
  };

  const confirmService = () => {
    let servicesObj = props.servicesDuck.services;
    servicesObj.push({
      name: props.cardCode,
      value: 1,
      prices: getPrice(),
    });
    setState({
      ...state,
      servicesCount: 1,
    });

    notification.open({
      message: t("product.productAdded"),
      top: 120,
      duration: 3,
      className: "product-added-notification-message",
    });

    dispatch(setServices(servicesObj));
  };

  const getPrice = () => {
    let price;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === props.cardCode) {
        price = product.prices;
      }
    });
    return price;
  };

  return (
    <div className={"home-service-carousel-card-container"}>
      <Image
        src={props.src}
        alt={props.cardCode}
        className={"home-service-carousel-card-image"}
        preview={false}
      />

      <div className={"home-service-carousel-card-badge"}>
        <Image
          src={props.badge}
          alt={props.cardCode}
          className={"home-service-carousel-card-badge-image"}
          preview={false}
        />
      </div>

      <Col className={"home-service-carousel-card-data-container"}>
        <HomeServiceContent
          cardTitle={props.cardTitle}
          cardSubtitle={props.cardSubtitle}
          cardDescription={props.cardDescription}
          currency={props.currency}
          priceForItem={formatValue(props.priceForItem)}
          target={props.target}
        />

        <Text
          className={"home-faq-more-text home-service-carousel-more-text"}
          onClick={handleDiscoverMore}
        >
          <span className={"home-faq-more-symbol"}>+</span>
          <u>{t("home.servicesCarousel.learnMore")}</u>
        </Text>

        <Row className={"home-carousel-card-buttons-container"}>
          {state.servicesCount > 0 && (
            <Col
              className={"checkout-service-card-footer-add-buttons-container"}
            >
              <Image
                src={Arrow}
                alt={"minus"}
                preview={false}
                onClick={removeItem}
              />
              <div className={"checkout-service-card-footer-numbers-container"}>
                {state.servicesCount}
              </div>
              <Image
                src={Arrow}
                alt={"minus"}
                preview={false}
                style={{ transform: "rotate(180deg)" }}
                onClick={addItem}
              />
            </Col>
          )}

          <Col
            className={"checkout-service-card-footer-confirm-button-container"}
          >
            {state.servicesCount > 0 && (
              <Button
                className={
                  "checkout-service-card-footer-confirm-button green-border"
                }
              >
                <Image src={CheckIconGreen} alt={"checked"} preview={false} />
              </Button>
            )}
            {state.servicesCount === 0 && (
              <Button
                className={"checkout-service-card-footer-confirm-button"}
                onClick={confirmService}
              >
                <span>+</span>
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </div>
  );
};

HomeServiceCarouselCard.defaultProps = {
  currency: "€",
  priceForItem: "-",
};

const mapStateToProps = (state) => ({
  servicesDuck: state.servicesDuck,
  productsListDuck: state.productsListDuck,
});

export default connect(mapStateToProps)(HomeServiceCarouselCard);
