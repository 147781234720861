import React from "react";

// STYLES
import './FooterCard.css';

import { Typography, Col, Row, Image } from "antd";

const FooterCard = (props) => {

  const { Title } = Typography;

  return (
    <Col className={'footer-card-container'}>
      {
        props.badge &&
        <Image
          src={props.badge}
          alt={'badge'}
          preview={false}
          className={"footer-card-image"}
        />
      }
      <Row>
        <Title className={'footer-card-title'}>{props.title}</Title>
      </Row>
      <div className={'footer-card-title-line'} />
      {
        props.content1 &&
        <Row>
          <a className={'footer-card-content'} href={props.href1} target={props.externalLink1 ? "_blank" : "_self"} rel="noreferrer">{props.content1}</a>
        </Row>
      }
      {
        props.content2 &&
        <Row>
          <a className={'footer-card-content'} href={props.href2} target={props.externalLink2 ? "_blank" : "_self"} rel="noreferrer">{props.content2}</a>
        </Row>
      }
      {
        props.content3 &&
        <Row>
          <a className={'footer-card-content'} href={props.href3} target={props.externalLink3 ? "_blank" : "_self"} rel="noreferrer">{props.content3}</a>
        </Row>
      }
      {/* {
        props.content4 &&
        <Row>
          <a className={'footer-card-content'} href={props.href4} target={props.externalLink4 ? "_blank" : "_self"} rel="noreferrer">{props.content4}</a>
        </Row>
      } */}
    </Col>
  )
}

export default FooterCard