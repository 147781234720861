import React from "react";

// STYLES
import './HeaderFlightsListItem.css';

// ASSETS
import FlightItemIcon from '../../../assets/images/searchFlight/flight-desktop-route-icon.svg';
import LLCLogo from '../../../assets/images/checkout/luggage-icon-white.svg';
import VIPLOUNGELogo from '../../../assets/images/checkout/vip-icon-white.svg';

import { Row, Col, Image, Typography } from "antd";
import { useTranslation } from "react-i18next";

const HeaderFlightsListItem = (props) => {

  const { Text } = Typography;
  const { t } = useTranslation();

  const stopovers = () => {
    let connections;
    switch (props.stopovers) {
      case (0):
        connections = t("home.searchFlights.direct");
        break;
      case (1):
        connections = '1' + t("home.searchFlights.stepover");
        break;
      case (2):
        connections = '2' + t("home.searchFlights.stepover");
        break;
      default:
        connections = '2+' + t("home.searchFlights.stepover");
        break;
    }
    return connections;
  }

  return (
    <Row className={"header-flights-list-item-container"}>
      <Image
        className={"header-flights-list-airline-logo"}
        src={props.airlineLogo}
        alt={'flight-airline-logo'}
        preview={false}
      />
      <Col>
        <div>{props.departure}</div>
        <div>{props.departureCity}</div>
      </Col>
      <Col className={'flights-list-item-time-travel-text-container'}>
        <Image
          src={FlightItemIcon}
          alt={'flight-item-icon'}
          preview={false}
        />
        <Text className={'flights-list-item-time-travel-airport'}>{props.flightDuration}</Text>
      </Col>
      <Col>
        <div>{props.arrival}</div>
        <div>{props.arrivalCity}</div>
      </Col>

      <Col>
        <div>{props.departureHours}</div>
        <div>{props.departureDate}</div>
      </Col>

      <Col>
        <div>{props.arrivalHours}</div>
        <div>{props.arrivalDate}</div>
      </Col>

      <Col>
        <div>{props.flightCode}</div>
        <div>{stopovers()}</div>
      </Col>

      <Row className={'flights-list-item-available-services-container'}>
        {
          props.LLC &&
          <div className={'flights-list-item-available-services-service'}>
            <Image
              src={LLCLogo}
              alt={'LLC'}
              preview={false}
            />
          </div>
        }
        {
          props.VIPLOUNGE &&
          <div className={'flights-list-item-available-services-service'}>
            <Image
              src={VIPLOUNGELogo}
              alt={'VIP Lounge Pass'}
              preview={false}
            />
          </div>
        }
      </Row>
    </Row>
  )
}

export default HeaderFlightsListItem