import React from "react";

// STYLES
import './SiderLogin.css';

// CAROUSEL
import { Carousel, Col, Image, Typography } from 'antd';

// ASSETS
import logo from '../../../assets/images/logo/logo-sos-travel-white.svg';

import { ENVIRONMENT, carouselTimer } from "../../../utils/properties";
import { useTranslation } from "react-i18next";

const SiderLogin = () => {

	const { Link, Text } = Typography;
	const { t } = useTranslation();

	return (
		<div>
			<Carousel autoplay autoplaySpeed={carouselTimer} className={"login-carousel"}>
				<Col>
					<div className={'login-carousel-image'} style={{ backgroundImage: `url(${ENVIRONMENT.CDN_BASE_PATH}/static/web/loginCarousel/login-carousel-1.jpg)`}}>
						<div className={"login-carousel-image-mask"} />
						<Text className='login-sider-description-container'>
							{t("signin.siderCarousel.description1")}
						</Text>
					</div>
				</Col>
				<Col>
					<div className={'login-carousel-image'}  style={{ backgroundImage: `url(${ENVIRONMENT.CDN_BASE_PATH}/static/web/loginCarousel/login-carousel-2.jpg)`}}>
						<div className={"login-carousel-image-mask"} />
						<Text className='login-sider-description-container'>
							{t("signin.siderCarousel.description2")}
						</Text>
					</div>
				</Col>
				<Col>
					<div className={'login-carousel-image'} style={{ backgroundImage: `url(${ENVIRONMENT.CDN_BASE_PATH}/static/web/loginCarousel/login-carousel-3.jpg)`}}>
						<div className={"login-carousel-image-mask"} />
						<Text className='login-sider-description-container'>
							{t("signin.siderCarousel.description3")}
						</Text>
					</div>
				</Col>
			</Carousel>
			<div className='login-sider-logo-container'>
				<Link href={ENVIRONMENT.ROUTING.BASE_URL}>
					<Image
						src={logo}
						alt={'logo'}
						preview={false}
					/>
				</Link>
			</div>

		</div>
	)
}

export default SiderLogin