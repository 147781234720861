import React from "react";

// STYLES
import './HeaderLogin.css'

// COMPONENTS
import CustomButton from "../customButton/CustomButton";

// ASSETS
import logo from '../../../assets/images/logo/logo-sos-travel-gray.svg';

import { useTranslation } from 'react-i18next';
import { Typography, Image } from 'antd';
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../../utils/properties";

const HeaderLogin = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { Link } = Typography;

  const signIn = () => {
    navigate(`${ENVIRONMENT.ROUTING.SIGNUP_URL}`);
  }

  const signUp = () => {
    navigate(`${ENVIRONMENT.ROUTING.SIGNIN_URL}`);
  }

  return (
    <div className='login-header-container'>
      <div className='login-header-logo-container-mobile'>
        <Link href={ENVIRONMENT.ROUTING.BASE_URL}>
          <Image
            src={logo}
            alt={'logo'}
            className='login-header-logo-mobile'
            preview={false}
          />
        </Link>
      </div>

      <div className='login-header-button-container'>
        {
          props.page === "signin" &&
          <>
            <div className='login-have-account'>{t('header.dontHaveAccount')}</div>
            <CustomButton
              type={'transparent'}
              content={t("profile.dropdownLinks.registerNow")}
              clickCallback={signIn}
            />
          </>
        }
        {
          props.page === "signup" &&
          <>
            <div className='login-have-account'>{t('header.haveAccount')}</div>
            <CustomButton
              type={'transparent'}
              content={t("profile.dropdownLinks.login")}
              clickCallback={signUp}
            />
          </>
        }
      </div>
    </div>
  )
}

export default HeaderLogin