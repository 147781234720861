
import React from "react";

// STYLES
import './HomeServiceInfo.css';

import { getValueFromLang } from "../../../utils/utilities";

import { Col, Typography, Image } from "antd";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../../utils/properties";
import { connect } from "react-redux";

const HomeServiceInfo = (props) => {

  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDiscoverMore = () => {
    let path = "";
    switch (props.code) {
      case "LLC":
        path = "LLC";
        break;
      case "VIPLOUNGE":
        path = "VIPLOUNGE"
        break;
      case "TLDRFM":
        path = "DrTravel";
        break;
      case "TLDRST":
        path = "DrTravel";
        break;
      default:
        path = "LLC";
        break;
    }
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}${path}-detail`);
  }

  return (
    <Col className={'home-service-info-container'}>
      <Image
        src={props.badge}
        alt={'badge'}
        preview={false}
        className={'home-service-info-badge'}
      />

      <Title className={'home-service-info-title'}>{getValueFromLang(props.title, props.languageDuck.currentLanguage)}</Title>

      <div className={'footer-card-title-line'} />

      <div className={'home-service-info-description-container'}>
        <Text className={'home-service-info-description'}>{getValueFromLang(props.description, props.languageDuck.currentLanguage)}</Text>
      </div>

      <Text className={'home-faq-more-text home-service-more-text'}>
        <span className={'home-faq-more-symbol'}>+</span><u onClick={handleDiscoverMore}>{t("home.servicesCarousel.learnMore")}</u>
      </Text>
    </Col>
  )
}

const mapStateToProps = state => ({
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(HomeServiceInfo)