import React, { useState } from "react";

// STYLES
import './Newsletter.css';

// ASSETS
import NewsletterIcon from "../../../assets/images/newsletter/newsletter-icon.svg";

// REDUX
import { connect } from "react-redux";

import { Typography, Input, Row, Checkbox, Image, Button } from "antd";
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { emailValidation } from "../../../utils/properties";

const Newsletter = (props) => {

  const [state, setState] = useState({
    errorCheckbox: false,
    acceptTerms: false,
    validEmail: true
  })

  const { Text, Title } = Typography;
  const { t } = useTranslation();

  const formikLogin = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: (values) => {
      sendNewsletter(values);
    }
  });

  const sendNewsletter = (email) => {
    if (!emailValidation(email.email)) {
      return (
        setState({
          ...state,
          validEmail: false
        })
      )
    }

    if (state.acceptTerms === false) {
      return (
        setState({
          ...state,
          errorCheckbox: true
        })
      )
    }
  }

  const onChangeCheckbox = () => {
    setState({
      ...state,
      acceptTerms: !state.acceptTerms,
      errorCheckbox: false
    })
  }

  const handleEmailInputFocus = () => {
    setState({
      ...state,
      validEmail: true
    })
  }

  const getComponentData = (id) => {
    let result;
    props.newsletterDataDuck.newsletterData.data.components.map((component) => {
      if(component.id === id) {
        if(component.type === "image" || component.type === "link") {
          return result = component.value;
        }
        component.translations.map((translation) => {
          if(translation.lang === props.languageDuck.currentLanguage.toLowerCase()) {
            result = translation.text;
          }
        })
      }
    })
    return result;
  }

  return (
    <div 
    className={'newsletter-container'}
    style={{backgroundImage: `url(${getComponentData("hero")})`}}
    >
      <div className={'newsletter-container-mask'} />
      <Text className={'newsletter-background-text'}>{t('newsletter.newsletter')}</Text>
      <Title className={'newsletter-title-text'}>{getComponentData("title")}</Title>
      <Row className={'newsletter-input-container'}>
        <Input
          name={'email'}
          id={'email'}
          placeholder={t('newsletter.email')}
          onChange={formikLogin.handleChange}
          value={formikLogin.values.email}
          className={'newsletter-input'}
          onFocus={handleEmailInputFocus}
          autoComplete={'off'}
        />
        {
          !state.validEmail &&
          <Text className={'newsletter-invalid-field-message'}>{t('general.invalidEmail')}</Text>
        }
        {
          state.errorCheckbox &&
          <Text className={'newsletter-invalid-field-message'}>{t('general.acceptTerms')}</Text>
        }

        <Button
          onClick={formikLogin.submitForm}
          className={'newsletter-input-submit'}
        >
          <Text className={'newsletter-input-submit-text'}>{t("newsletter.subscribe")}</Text>
          <Image
            src={NewsletterIcon}
            alt={'submit'}
            className={'newsletter-input-submit-icon'}
            preview={false}
          />
        </Button>

      </Row>
      <Row className={'newsletter-terms-container'}>
        <Checkbox
          onChange={onChangeCheckbox}
        />
        <Text className={'newsletter-terms-text'}>{t('newsletter.terms')}</Text>
      </Row>
    </div>
  )
}

const mapStateToProps = state => ({
  newsletterDataDuck: state.newsletterDataDuck,
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(Newsletter)