import React, { useState } from "react";

// STYLES
import '../SignIn/SignIn.css'

// COMPONENTS
import CustomButton from "../../components/functional_components/customButton/CustomButton";
import SEO from "../../components/functional_components/SEO/SEO";

// ACTIONS
import { useDispatch } from "react-redux";

import { Row, Col, Typography } from "antd";
import OtpInput from "react-otp-input";
import { useTranslation } from 'react-i18next';
import { ENVIRONMENT } from '../../utils/properties';
import { useNavigate, useLocation } from "react-router-dom";
import { sendOTP } from "../../redux/actions/actions";
import { get } from "lodash";

const SignUpOTP = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Title } = Typography;
  const location = useLocation();

  const [state, setState] = useState({
    otp: '',
    otpComplete: false,
    otpError: false,
    isLoading: false
  });


  const handleSendOTP = async () => {
    if (state.otp.length === 6) {
      try {
        setState({
          ...state,
          isLoading: true
        })
        let OTPCode = {
          token: state.otp
        }
        const OTP = await sendOTP(dispatch, OTPCode);
        if (!OTP.error) {
          navigate(`${ENVIRONMENT.ROUTING.SIGNUP_URL}/step2`);
        } else {
          setState({
            ...state,
            otpError: true,
            isLoading: false
          })
        }
      } catch (err) {
        // console.log('err', err)
      }
    }
  };

  const otpHandleChange = (otp) => {
    let otpComplete = false;
    if (otp.length === 6) {
      otpComplete = true
    }
    setState({
      ...state,
      otp: otp,
      otpComplete: otpComplete,
      otpError: false
    })
  }

  const OTPStyle = () => {
    let OTPStyle = 'otp-input';
    if (state.otpComplete) {
      OTPStyle = 'otp-input otp-complete';
      if (state.otpError) {
        OTPStyle = 'otp-input otp-error';
      }
    }
    return OTPStyle;
  }

  return (
    <div className={'login-screen-container'}>
      <SEO
        page={"SignUp_OTP"}
        url={"signup/OTP"}
      />

      <Row className={'login-title-container'}>
        <Title className={'login-title'}>{t('signup.page2.title')}</Title>
      </Row>

      <Col className={'signup-otp-label'}>
        <label className={'login-label'}>{t('signup.page2.otpTitle')}</label>
        <p className={'login-subtitle'}>{t('signup.page2.otpSubtitle')} <span style={{ color: 'var(--main-black)' }}>{get(location.state, "email", t('general.emailPlaceholder'))}</span></p>
      </Col>

      <OtpInput
        value={state.otp}
        onChange={otpHandleChange}
        numInputs={6}
        containerStyle={'otp-container'}
        inputStyle={OTPStyle()}
        renderInput={(props) => <input {...props} />}
        shouldAutoFocus={true}
      />

      <Row className={'login-submit-button-container mt-10'}>
        <CustomButton
          type="primary"
          isLoading={state.isLoading}
          clickCallback={handleSendOTP}
          content={t('general.continue')}
        />
      </Row>
    </div>
  )
}

export default SignUpOTP