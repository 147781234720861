import { combineReducers } from 'redux';
// API
import userInfoDuck from "../ducks/UserInfo";
import languageDuck from "../ducks/Language";
// import loadingDuck from "../ducks/Loading";
import flightsDuck from '../ducks/Flights';
import airportsDuck from '../ducks/Airports';
import userDataDuck from '../ducks/UserData';
import servicesDuck from '../ducks/Services';
import loungeDuck from '../ducks/Lounge';
import availableServicesDuck from "../ducks/AvailableServices";
import paymentTokenDuck from '../ducks/PaymentToken';
import productsListDuck from '../ducks/ProductsList';
import airlinesDuck from '../ducks/Airlines';
import currencyDuck from '../ducks/Currency';
import cartDuck from '../ducks/Cart';
import homeDataDuck from '../ducks/HomeData';
import newsletterDataDuck from '../ducks/NewsletterData';
import discountDuck from '../ducks/Discount';
import productsDataDuck from '../ducks/ProductsData';
import faqsDuck from '../ducks/Faqs';

const appReducer = combineReducers({
  userInfoDuck,
  languageDuck,
  // loadingDuck,
  flightsDuck,
  airportsDuck,
  userDataDuck,
  servicesDuck,
  loungeDuck,
  availableServicesDuck,
  paymentTokenDuck,
  productsListDuck,
  airlinesDuck,
  currencyDuck,
  cartDuck,
  homeDataDuck,
  newsletterDataDuck,
  discountDuck,
  productsDataDuck,
  faqsDuck,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;
