import React from 'react';

// STYLES
import './CustomButton.css';
import '../../../style.css';

import { Button } from "antd";

const CustomButton = (props) => {

  const getCurrentClassName = () => {
    let currentClassName = null;
    switch (props.type) {
      case "primary":
        currentClassName = "button-primary-style"
        break;
      case "primary-xl":
        currentClassName = "button-primary-style button-primary-style-xl"
        break;
      case "primary-lg":
        currentClassName = "button-primary-style button-primary-style-lg"
        break;
      case "primary-sm":
        currentClassName = "button-primary-style button-primary-style-sm"
        break;
      case "primary-xs":
        currentClassName = "button-primary-style button-primary-style-xs"
        break;
      case "primary-xs-confirm":
        currentClassName = "button-primary-style button-primary-style-xs-confirm"
        break;
      case "secondary":
        currentClassName = "button-secondary-style"
        break;
      case "searchFlight":
        currentClassName = "button-search-flight-style"
        break;
      case "provider":
        currentClassName = "button-provider-style"
        break;
      case "provider-fb":
        currentClassName = "button-provider-style button-provider-style-fb"
        break;
      case "provider-apple":
        currentClassName = "button-provider-style button-provider-style-apple"
        break;
      case "transparent":
        currentClassName = "button-transparent-style"
        break;
      case "transparent-lg":
        currentClassName = "button-transparent-style button-transparent-style-lg"
        break;
      default:
        currentClassName = "button-default-style"
        break;
    }
    return currentClassName;
  }

  return (
    <div style={
      { width: props.isBlock ? props.currentWidth : null, float: props.floatRight ? 'right' : null }
    }
      className={props.className}
    >
      <Button
        disabled={props.isDisabled}
        type={props.type}
        loading={props.isLoading}
        onClick={event => !props.isDisabled && !!props.clickCallback ? props.clickCallback(event) : null} //*ga*
        size={props.currentSize}
        className={getCurrentClassName()}
        block={props.isBlock}
        icon={props.currentIcon}
        href={props.href}
        style={props.style}
      >
        {props.isLoading ? " " : props.content}
      </Button>
    </div>
  )
}

CustomButton.defaultProps = {
  isDisabled: false,
  type: "primary",
  isBlock: false,
  floatRight: false,
  className: '',
  content: "Submit"
}

export default CustomButton;