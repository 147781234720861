import React from "react";

// STYLES
import './Page404.css';

// COMPONENTS
import HeaderHome from "../../components/functional_components/headerHome/HeaderHome";
import MobileNavbar from "../../components/hooks_components/mobileNavbar/MobileNavbar";

// ASSETS
import Error404 from "../../assets/images/error/404-page-image.svg";

import { Col, Image, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../utils/properties";
import { useTranslation } from "react-i18next";

const Page404 = () => {

  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backToHome = () => {
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}`)
  }

  return (
    <Col>
      <Row className={"thank-you-page-header-container"}>
        <HeaderHome dark />
      </Row>

      <Col className={"error-404-content-container"}>
        <Image
          src={Error404}
          alt={'error'}
          preview={false}
        />
        <Col className={"error-404-content-text-container"}>
          <Title>{t("404page.title")}<br /> {t("404page.sorry")}! :O</Title>
          <Text>{t("404page.404notFound")}<br />
            <span onClick={backToHome}>{t("404page.report")}</span> {t("general.and")} <span onClick={backToHome}>{t("404page.backToHome")}</span>.</Text>
        </Col>
      </Col>

      <MobileNavbar />
    </Col>
  )
}

export default Page404