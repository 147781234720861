const SET_CART = 'SET_CART'
const INIT_CART = 'INIT_CART'

export function setCart(value) {
  return {
    type: SET_CART,
    payload: {
      cart: value
    }
  };
}

export function initCart() {
  return {
    type: INIT_CART,
    payload: {
      cart: false
    }
  }
}

const INIT_STATE = {
  cart: false
}

export default function cartDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_CART:
      newState.cart = action.payload.cart;
      break;
    case INIT_CART:
      newState.cart = false;
      break;
    default:
      break;
  }
  return newState;
}