import React, { useEffect, useState } from "react";

// STYLES
import './CheckoutHeader.css';

// COMPONENTS
import GoBackButton from "../goBackButton/GoBackButton";
import HeaderHome from "../headerHome/HeaderHome";

// REDUX
import { connect } from "react-redux";

import { Col, Typography, Row } from "antd";
import { useTranslation } from 'react-i18next';

const CheckoutHeader = (props) => {

  const [state, setState] = useState({
    desktop: window.innerWidth >= 768 ? true : false
  })

  const { Text } = Typography;
  const { t } = useTranslation();

  useEffect(() => {
    const setWidth = () => {
      let width = window.innerWidth;
      if (width >= 768 && !state.desktop) {
        setState({
          ...state,
          desktop: true
        })
      }

      if (width < 768 && state.desktop) {
        setState({
          ...state,
          desktop: false
        })
      }
    }
    window.addEventListener('resize', setWidth)
    return () => window.removeEventListener('resize', setWidth)
  })

  const handleHeaderLabel = () => {
    let title = '';
    switch (props.step) {
      case 1:
        title = "availableServices";
        break;
      case 2:
        title = "recap";
        break;
      case 3:
        title = "payment";
        break;
      default:
        title = "availableServices";
        break;
    }
    return title
  }

  return (
    <Col
      className={props.faqsPage ? 'checkout-header-container faq-header-container' : 'checkout-header-container'}
    >
      <GoBackButton color={props.color} />
      <Row className={"checkout-header-desktop-container"}>
        <HeaderHome dark={state.desktop} checkout faqsPage={props.faqsPage} />
      </Row>
      {
        !props.faqsPage &&
        <Col className={'checkout-header-content-container'}>
          <Row className={'checkout-header-progress-bar-container'}>
            <Row className={'checkout-header-bar-progress-bar checkout-header-active-bar-progress-bar'} />
            <Row
              className={props.step > 1 ? 'checkout-header-bar-progress-bar checkout-header-active-bar-progress-bar' : 'checkout-header-bar-progress-bar'}
              style={state.desktop && props.step < 2 ? { backgroundColor: '#EAEAEA' } : {}}
            />
            <Row
              className={props.step > 2 ? 'checkout-header-bar-progress-bar checkout-header-active-bar-progress-bar' : 'checkout-header-bar-progress-bar'}
              style={state.desktop && props.step < 3 ? { backgroundColor: '#EAEAEA' } : {}}
            />
          </Row>
          {
            (props.userInfoDuck.userInfo.type === "B2C" || (props.userInfoDuck.userInfo.type !== "B2C" && props.step > 1)) &&
            <>
              <Text className={'checkout-header-label'}>{t(`checkout.${handleHeaderLabel()}`)}</Text>
              <Text className={'checkout-header-description'}>{t(`checkout.${handleHeaderLabel()}Description`)}</Text>
            </>
          }
        </Col>
      }
    </Col >
  )
}

const mapStateToProps = state => ({
  userInfoDuck: state.userInfoDuck
})

export default connect(mapStateToProps)(CheckoutHeader)