const SET_FLIGHTS = 'SET_FLIGHTS'
const INIT_FLIGHTS = 'INIT_FLIGHTS'

export function setFlights(value) {
  return {
    type: SET_FLIGHTS,
    payload: {
      flights: value
    }
  };
}

export function initFlights() {
  return {
    type: INIT_FLIGHTS,
    payload: {
      flights: {}
    }
  }
}

const INIT_STATE = {
  flights: {}
}

export default function flightsDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_FLIGHTS:
      newState.flights = action.payload.flights;
      break;
    case INIT_FLIGHTS:
      newState.flights = {};
      break;
    default:
      break;
  }
  return newState;
}
