import React, { useEffect, useState } from "react";

// STYLES
import "./Orders.css";

// ASSETS
import DownloadIcon from "../../../assets/images/profile/receipt-download.svg";
import NoOrdersImage from "../../../assets/images/profile/orders-no-item.svg";

// REDUX
import { getDownloadItemReceipt, getDownloadReceipt, getOrders } from "../../../redux/actions/actions";
import { connect } from "react-redux";

// COMPONENTS
import OrdersFilter from "../../../components/functional_components/ordersFilter/OrdersFilter";
import SEO from "../../../components/functional_components/SEO/SEO";

import { Col, Image, Row, Typography, Skeleton } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatValue } from "../../../utils/utilities";

const Orders = (props) => {
  const [state, setState] = useState({
    loading: true,
    orders: null,
  });

  let b2c = props.userInfoDuck.userInfo.type === "B2C";
  let b2b_master = props.userInfoDuck.userInfo.type === "B2B_MASTER";
  let b2b_admin = props.userInfoDuck.userInfo.type === "B2B_ADMIN";
  let preloadFilters = b2b_master || b2b_admin;

  const [filters, setFilters] = useState({
    dateFrom: preloadFilters
      ? moment().subtract(1, "year").format("YYYY-MM-DD")
      : "",
    dateTo: preloadFilters ? moment().add(1, "day").format("YYYY-MM-DD") : "",
  });

  const [filteringItems, setFilteringItems] = useState({
    agencies: [],
    agents: [],
    clients: [],
  });

  const { Text, Title } = Typography;
  const { t } = useTranslation();

  useEffect(() => {
    const saveFilters = Object.entries(filters).every(([key, value]) => {
      if (key === "partnerId" && value) return false;
      if (key === "userId" && value) return false;
      return true;
    });
    dataList(filters, { saveFilters });
  }, [filters]);

  const dataList = async (params, { saveFilters }) => {
    let userOrders = await getOrders(
      Object.fromEntries(Object.entries(params).filter(([, value]) => value))
    );

    let orders = [];

    userOrders?.data?.map((order) => {
      orders.push({
        date: moment(order.creationDate).format("DD MMMM YYYY"),
        receiptNumber: order.orderId,
        products: order.items.map((item) => {
          return {
            name: item.productCode,
            amount: item.quantity,
            cost: item.initialPrice ? item.initialPrice : item.price,
          };
        }),
        initialCost: order.initialTotalOrder,
        finalCost: order.totalOrder,
        currency: order.currency === "EUR" ? "€" : "$",
        user: order.username,
        agency: order.partnerName,
        partnerId: order.partnerId,
        userId: order.userId,
        client: order.orderCustomerName,
        orderId: order.orderId
      });
    });

    setState({
      ...state,
      loading: false,
      orders: orders.reverse(),
    });

    if (saveFilters) {
      setFilteringItems({
        agencies: reduceFilters(
          orders.map(({ agency, partnerId }) => ({
            name: agency,
            id: partnerId,
          }))
        ),
        agents: reduceFilters(
          orders.map(({ user, userId }) => ({
            name: user,
            id: userId,
          }))
        ),
        clients: reduceFilters(
          orders.map(({ client }, i) => ({
            name: client,
            id: i,
          })),
          "client"
        ),
      });
    }
  };

  const downloadReceipt = (id) => async () => {
    await getDownloadReceipt(id, t("profile.orders.receiptDownloadError"));
  }

  const downloadItem = (id, item) => async () => {
    await getDownloadItemReceipt(id, item, t("profile.orders.receiptDownloadError"));
  }

  return (
    <Col style={{ maxWidth: "700px" }}>
      <SEO
        page={"Orders"}
        url={"profile/orders"}
      />

      <Col className={"profile-account-title-desktop"}>
        <Title>{t("profile.orders.myOrders")}</Title>
        <div className={"profile-account-title-desktop-line"} />
      </Col>
      {!b2c && (
        <OrdersFilter
          agencies={b2b_master && filteringItems.agencies}
          agents={(b2b_admin || b2b_master) && filteringItems.agents}
          clients={filteringItems.clients}
          date
          serviceType
          setFilters={setFilters}
          sort={setState}
        />
      )}
      {state.loading && (
        <Col className={"profile-loader-container"}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Col>
      )}
      {!state.loading &&
        state.orders.length > 0 &&
        state.orders.map((order, index) => {
          return (
            <Col key={index} className={"profile-orders-container"}>
              <Row className={"profile-orders-date-container"}>
                <Text className={"profile-orders-date"}>
                  {b2c ? order.date : `#${order.receiptNumber}`}
                </Text>
                <Text
                  className={"profile-orders-receipt-download"}
                  onClick={downloadReceipt(order.orderId)}
                >
                  {t("profile.orders.receiptDownload")}
                </Text>
              </Row>

              {b2c && (
                <Text className={"profile-orders-receipt-number-text"}>
                  {t("profile.orders.receiptNumber")}: #{order.receiptNumber}
                </Text>
              )}

              {!b2c && (
                <Row className={"profile-orders-b2b-receipt-info"}>
                  <Col>
                    <Text>{t("profile.orders.user")}</Text>
                    <Text>{order.user}</Text>
                  </Col>
                  <Col>
                    <Text>{t("general.agency")}</Text>
                    <Text>{order.agency}</Text>
                  </Col>
                  <Col>
                    <Text>{t("profile.orders.filter.date")}</Text>
                    <Text>{order.date}</Text>
                  </Col>
                  <Col>
                    <Text>{t("profile.orders.orderNumber")}</Text>
                    <Text>{order.receiptNumber}</Text>
                  </Col>
                </Row>
              )}

              <Row className={"profile-orders-labels-container"}>
                <Text>{t("profile.orders.typology")}</Text>
                <Text>{t("profile.orders.quantity")}</Text>
                <Text>{t("profile.orders.amount")}</Text>
              </Row>

              <div className={"dotted-line"} />

              {order.products.map((product, index) => {
                return (
                  <Row
                    key={index}
                    className={"profile-orders-deatils-container"}
                  >
                    <Text>
                      <Image
                        src={DownloadIcon}
                        alt={"DownloadIcon"}
                        preview={false}
                        onClick={downloadItem(order.orderId, product.name)}
                      />
                      {product.name}
                    </Text>
                    <Text>{product.amount}</Text>
                    <Text className={"profile-orders-order-amount"}>
                      {order.currency}&nbsp;{formatValue(product.cost)}
                    </Text>
                  </Row>
                );
              })}

              <div className={"dotted-line"} />

              <Row className={"profile-orders-total-cost-container"}>
                <Text>{t("profile.orders.amountCharged")}:</Text>
                {order.initialCost && (
                  <Text className={"profile-orders-total-cost-text"}>
                    <span className={"profile-orders-initial-cost-text"}>
                      {order.currency}&nbsp;{formatValue(order.initialCost)}
                    </span>
                    {order.currency}&nbsp;{formatValue(order.finalCost)}
                  </Text>
                )}
                {!order.initialCost && (
                  <Text className={"profile-orders-total-cost-text"}>
                    {order.currency}&nbsp;{formatValue(order.finalCost)}
                  </Text>
                )}
              </Row>
            </Col>
          );
        })}
      {!state.loading && state.orders.length === 0 && (
        <Col className={"profile-orders-no-items-image-container"}>
          <Image alt={"No items"} src={NoOrdersImage} preview={false} />
          <Text>{t("profile.orders.noOrders")}</Text>
        </Col>
      )}
    </Col>
  );
};

const mapStateToProps = (state) => ({
  productsListDuck: state.productsListDuck,
  userInfoDuck: state.userInfoDuck,
});

function reduceFilters(items, type) {
  if (items && items.length) {
    // eslint-disable-next-line no-undef
    const agencies = items.reduce((result, { name, id }) => {
      if (
        !result.find((a) => (type === "client" ? a.name === name : a.id === id))
      )
        result.push({ name, id });
      return result;
    }, []);
    return agencies;
  }
  return [];
}

export default connect(mapStateToProps)(Orders);
