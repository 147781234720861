const SET_PRODUCTSDATA = 'SET_PRODUCTSDATA'
const INIT_PRODUCTSDATA = 'INIT_PRODUCTSDATA'

export function setProductsData(value) {
  return {
    type: SET_PRODUCTSDATA,
    payload: {
      productsData: value
    }
  };
}

export function initProductsData() {
  return {
    type: INIT_PRODUCTSDATA,
    payload: {
      productsData: {}
    }
  }
}

const INIT_STATE = {
  productsData: {}
}

export default function productsDataDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_PRODUCTSDATA:
      newState.productsData = action.payload.productsData;
      break;
    case INIT_PRODUCTSDATA:
      newState.productsData = {};
      break;
    default:
      break;
  }
  return newState;
}