import React, { useState } from "react";

// STYLES
import './SignIn.css';

// COMPONENTS
import CustomButton from "../../components/functional_components/customButton/CustomButton";
import ProviderButton from "../../components/functional_components/providerButton/ProviderButton";
import PrefixPhoneMenu from "../../components/hooks_components/prefixPhoneMenu/PrefixPhoneMenu";
import SEO from "../../components/functional_components/SEO/SEO";

// ASSETS
import FacebookIcon from "../../assets/images/social/logo-facebook.svg";
import GoogleIcon from "../../assets/images/social/logo-google.svg";
import AppleIcon from "../../assets/images/social/logo-apple.svg";
import HuaweiIcon from "../../assets/images/social/logo-huawei.svg";

// REDUX
import { useDispatch } from "react-redux";
import { doLogin, doLoginPhone } from '../../redux/actions/actions';

import { useNavigate, useLocation } from "react-router-dom";
import { ENVIRONMENT, emailValidation, passwordValidation, phoneValidation } from '../../utils/properties';
import { providersURL } from "../../utils/utilities";
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Input, Row, Col, Menu, Typography } from "antd";
import { get } from "lodash";

const SignIn = () => {

  const [state, setState] = useState({
    isLoading: false,
    acceptTerms: false,
    accessType: 'email',
    areacode: '39',
    prefixDisabled: false,
    validEmail: true,
    validPassword: true,
    validPhone: true
  });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { Title, Text } = Typography;
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClick = e => {
    setState({
      ...state,
      accessType: e.key
    });
  };

  const formikLogin = useFormik({
    initialValues: {
      username: '',
      phone: '',
      password: ''
    },
    onSubmit: (values) => {
      sendLogin(values);
    }
  });

  const sendLogin = async (values) => {
    setState({
      ...state,
      isLoading: true
    })
    try {
      let loginData = {}

      if (state.accessType === 'email') {
        const dataLogin = {
          email: values.username,
          password: values.password,
          source: "SOSTRAVEL"
        }

        if (!emailValidation(dataLogin.email)) {
          return (
            setState({
              ...state,
              validEmail: false
            })
          )
        }

        if (!passwordValidation(dataLogin.password)) {
          return (
            setState({
              ...state,
              validPassword: false
            })
          )
        }
        loginData = await doLogin(dispatch, dataLogin, "B2C", t("errorsCode.ER002"));
      } else {
        const dataLogin = {
          prefixPhone: `+${state.areacode}`,
          phone: `${values.phone}`,
          password: values.password,
          source: "SOSTRAVEL"
        }

        if (!phoneValidation(dataLogin.phone)) {
          return (
            setState({
              ...state,
              validPhone: false
            })
          )
        }

        if (!passwordValidation(dataLogin.password)) {
          return (
            setState({
              ...state,
              validPassword: false
            })
          )
        }

        loginData = await doLoginPhone(dispatch, dataLogin, "B2C", t("errorsCode.ER002"));
      }

      setState({
        ...state,
        isLoading: false
      })

      if (!loginData.error) {
        let pathname = get(location.state, "from.pathname", null);
        if (pathname) {
          navigate(pathname);
        } else {
          navigate(ENVIRONMENT.ROUTING.BASE_URL);
        }
      }
    } catch (err) {
      // console.log('err', err)
    }
  };

  const prefixHandleChange = (value) => {
    setState({
      ...state,
      areacode: value
    });
  }

  const handleEmailInputFocus = () => {
    setState({
      ...state,
      validEmail: true
    })
  }

  const handlePasswordInputFocus = () => {
    setState({
      ...state,
      validPassword: true
    })
  }

  const handlePhoneInputFocus = () => {
    setState({
      ...state,
      prefixDisabled: true,
      validPhone: true
    })
  }

  const handlePhoneInputBlur = () => {
    setState({
      ...state,
      prefixDisabled: false
    })
  }

  const handleForgotPsw = () => {
    navigate(`${ENVIRONMENT.ROUTING.SIGNUP_URL}/forgot-password`);
  }

  return (
    <div className={'login-screen-container'}>
      <SEO
        page={"SignIn"}
        url={"signin"}
      />

      <Row className={'login-title-container'}>
        <Title className={'login-title'}>{t('signin.title')}</Title>
      </Row>

      <Row className={'login-subtitle-container'}>
        <p className={'login-subtitle'}>{t('signin.subtitle')}</p>
      </Row>

      <Menu
        onClick={handleClick}
        selectedKeys={state.accessType}
        mode="horizontal"
        className={"login-access-type-menu-container"}
      >
        <Menu.Item
          key="email"
          style={state.accessType === 'email' ? { color: 'var(--main-black)' } : { color: 'var(--secondary-gray)' }}>
          {t('general.email')}
        </Menu.Item>
        <Menu.Item
          key="phone"
          style={state.accessType === 'email' ? { color: 'var(--secondary-gray)' } : { color: 'var(--main-black)' }}>
          {t('general.phone')}
        </Menu.Item>
      </Menu>
      {
        state.accessType === 'email' &&
        <Row>
          <label className='login-label'>{t('general.emailLabel')}</label>
          <Input
            id="username"
            name="username"
            placeholder={t('general.emailPlaceholder')}
            onChange={formikLogin.handleChange}
            onBlur={formikLogin.handleBlur}
            value={formikLogin.values.username}
            className={state.validEmail ? 'login-input-box' : 'login-input-box invalid-field'}
            onFocus={handleEmailInputFocus}
            autoComplete={"off"}
          />
          {
            !state.validEmail &&
            <Text className={'invalid-field-message'}>{t('general.invalidEmail')}</Text>
          }
        </Row>
      }
      {
        state.accessType === 'phone' &&
        <Row>
          <label className={'login-label'}>{t('general.phone')}</label>
          <Row className={'login-input-phone-container'}>
            <PrefixPhoneMenu
              handleChangeCallback={prefixHandleChange}
              disabled={state.prefixDisabled}
            />
            <Input
              id="phone"
              name="phone"
              placeholder={'123456'}
              onChange={formikLogin.handleChange}
              value={formikLogin.values.phone}
              className={state.validPhone ? 'login-input-box login-input-phone' : 'login-input-box login-input-phone invalid-field'}
              type={'number'}
              onFocus={handlePhoneInputFocus}
              onBlur={handlePhoneInputBlur}
              autoComplete={"off"}
            />
            {
              !state.validPhone &&
              <Text className={'invalid-field-message'}>{t('general.invalidPhone')}</Text>
            }
          </Row>
        </Row>
      }


      <Row>
        <Row className={'login-label-password-container'}>
          <label className={'login-label'}>{t('general.password')}</label>
          <p className={'login-forgot-pasword'} onClick={handleForgotPsw}>{t('signin.forgotPassword')}</p>
        </Row>
        <Input.Password
          id="password"
          name="password"
          placeholder={t('general.passwordPlaceholder')}
          onChange={formikLogin.handleChange}
          onBlur={formikLogin.handleBlur}
          value={formikLogin.values.password}
          onFocus={handlePasswordInputFocus}
          className={state.validPassword ? 'login-input-box' : 'login-input-box invalid-field'}
        />
        {
          !state.validPassword &&
          <Text className={'invalid-field-message'}>{t('general.invalidPassword')}</Text>
        }
      </Row>

      <Row className={'login-submit-button-container'}>
        <CustomButton
          type="primary"
          isLoading={state.isLoading}
          clickCallback={formikLogin.submitForm}
          content={t('general.continue')}
        />
      </Row>

      <div className='login-provider-separator-container'>
        <div className='login-provider-separator-text'>
          {t('general.or')}
        </div>
        <div className='login-provider-separator-line' />
      </div>

      <Col className={'login-providers-buttons-container'}>
        <Col className={'login-providers-buttons-responsive-row'}>
          <ProviderButton
            label={`${t("signin.signInWith")} ${t("signin.social.Facebook")}`}
            href={providersURL(get(location.state, "from.pathname", null), 'facebook')}
            logo={FacebookIcon}
            fb
          />
          <ProviderButton
            label={`${t("signin.signInWith")} ${t("signin.social.Google")}`}
            href={providersURL(get(location.state, "from.pathname", null), 'google')}
            logo={GoogleIcon}
          />
        </Col>
        <Col className={'login-providers-buttons-responsive-row'}>
          <ProviderButton
            label={`${t("signin.signInWith")} ${t("signin.social.Apple")}`}
            href={providersURL(get(location.state, "from.pathname", null), 'apple')}
            logo={AppleIcon}
            apple
          />
          <ProviderButton
            label={`${t("signin.signInWith")} ${t("signin.social.Huawei")}`}
            href={providersURL(get(location.state, "from.pathname", null), 'huawei')}
            logo={HuaweiIcon}
          />
        </Col>
      </Col>
    </div>
  )
}

export default SignIn;