import BraintreeDropIn from "./BraintreeDropIn";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../../utils/properties";
import { useDispatch, connect } from "react-redux";
import { initServices } from "../../../redux/ducks/Services";
import { Spin } from "antd";
import { showError } from "../../../utils/utilities";
import { useTranslation } from "react-i18next";

const Payment = (props) => {

  const [state, setState] = useState({
    loading: false
  })
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(props.paymentTokenDuck.paymentToken).length === 0) {
      navigate(`${ENVIRONMENT.ROUTING.BASE_URL}services-checkout`);
    }
  })


  const paymentCompleted = () => {
    let services = [];
    props.servicesDuck.services.map((service) => {
      services.push(service.name)
    })
    dispatch(initServices());
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}thank-you`, { state: { services: services } });
  }

  const paymentFailed = () => {
    setState({
      ...state,
      loading: false
    })
    showError(t("checkout.paymentError"))
  }

  const setLoading = () => {
    setState({
      ...state,
      loading: true
    })
  }

  const removeLoading = () => {
    setState({
      ...state,
      loading: false
    })
  }

  return (
    <div className={"payment-container"}>
      <BraintreeDropIn
        show={!state.loading}
        onPaymentCompleted={paymentCompleted}
        onPaymentFailed={paymentFailed}
        userData={location.state.userData}
        setLoading={setLoading}
        removeLoading={removeLoading}
        discount={location.state.discount}
      />
      <div className="terms-text">
        <p dangerouslySetInnerHTML={{__html: t("checkout.terms")}}></p>
      </div>
      {
        state.loading &&
        <div className={"payment-spin-container centered"}>
          <Spin />
        </div>
      }
    </div>
  );
}

const mapStateToProps = state => ({
  servicesDuck: state.servicesDuck,
  paymentTokenDuck: state.paymentTokenDuck
})

export default connect(mapStateToProps)(Payment)