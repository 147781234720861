import React, { useState } from "react";

// STYLES
import './FlightSearch.css';

// COMPONENTS
import CustomButton from "../../functional_components/customButton/CustomButton";
import FlightSearchInput from "../../functional_components/flightSearchInput/FlightSearchInput";

// ASSETS
import SwitchArrows from "../../../assets/images/searchFlight/switch-arrows.svg";
import MenuArrow from "../../../assets/images/searchFlight/menu-arrow.svg";
import DepartureIcon from "../../../assets/images/searchFlight/departure-icon.svg";
import ArrivalIcon from "../../../assets/images/searchFlight/arrival-icon.svg";

import { DatePicker, Button, Image, Select, Typography, ConfigProvider } from "antd";
import { ENVIRONMENT } from "../../../utils/properties";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import moment from "moment";
import { useTranslation } from 'react-i18next';

// ACTIONS
import { useDispatch, connect } from "react-redux";
import { doSearchFlights } from "../../../redux/actions/actions";

const FlightSearch = (props) => {

  const [state, setState] = useState({
    flightDate: '',
    searchFor: 'FlightRoute',
    loading: false,
    error: false
  })

  const { Option } = Select;
  const { Text } = Typography;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const airportsList = props.airportsDuck.airports.response;
  const airlinesList = props.airlinesDuck.airlines.response;

  const formikSearchFlight = useFormik({
    initialValues: {
      from: null,
      to: null,
      flightNumber: '',
      airline: null
    },
    onSubmit: (values) => {
      sendSearch(values);
    }
  });

  const sendSearch = async (values) => {
    let searchData;

    if (state.searchFor === "FlightRoute") {
      if (!values.from || !values.to) {
        return (
          setState({
            ...state,
            error: true,
            loading: false
          })
        )
      }
      searchData = {
        date: moment(state.flightDate._d).format('YYYY/MM/DD'),
        from: values.from,
        to: values.to
      }
      setState({
        ...state,
        loading: true
      })
      await doSearchFlights(dispatch, searchData);
    } else {
      if (!values.flightNumber || !values.airline) {
        return (
          setState({
            ...state,
            error: true,
            loading: false
          })
        )
      }
      searchData = {
        date: moment(state.flightDate._d).format('YYYY/MM/DD'),
        flightNumber: values.flightNumber,
        airline: values.airline
      }
      setState({
        ...state,
        loading: true
      })
      await doSearchFlights(dispatch, searchData);
    }

    setState({
      ...state,
      loading: false
    })

    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}flights-list`, { state: { data: searchData } })
  }

  const selectHandleChange = (value) => {
    setState({
      ...state,
      searchFor: value,
      error: false
    })
  }

  const onFlightDateChange = (dateString) => {
    setState({
      ...state,
      flightDate: dateString,
      error: false
    })
  }

  const handleSwipeClick = () => {
    let fromValue = formikSearchFlight.values.from;
    let toValue = formikSearchFlight.values.to;
    formikSearchFlight.values.from = toValue;
    formikSearchFlight.values.to = fromValue;

    setState({
      ...state,
      error: false
    })
  }


  const handleFromInputChange = (value) => {
    formikSearchFlight.values.from = value;
    setState({
      ...state,
      error: false
    })
  }

  const handleToInputChange = (value) => {
    formikSearchFlight.values.to = value;
    setState({
      ...state,
      error: false
    })
  }

  const handleAirlineInputChange = (value) => {
    formikSearchFlight.values.airline = value;
    setState({
      ...state,
      error: false
    })
  }

  const disabledDate = (current) => {
    return current < moment().startOf('day');
  }

  return (
    <>
      <div className={'home-flight-search-container'}>
        <>
          <Select
            defaultValue={"FlightRoute"}
            onChange={selectHandleChange}
            className={"home-flight-search-select"}
            suffixIcon={<Image src={MenuArrow} alt={"open menu"} preview={false} />}
          >
            <Option value="FlightRoute">{t("home.searchFlights.searchForRoute")}</Option>
            <Option value="FlightNumber">{t("home.searchFlights.searchForFlightNumber")}</Option>
          </Select>
          {
            state.searchFor === "FlightRoute" &&
            <>
              <FlightSearchInput
                id={'from'}
                onChange={handleFromInputChange}
                value={formikSearchFlight.values.from}
                placeholder={t("home.searchFlights.from")}
                icon={DepartureIcon}
                iconAlt={'departure'}
                airportsList={airportsList}
              />

              <FlightSearchInput
                id={'to'}
                onChange={handleToInputChange}
                value={formikSearchFlight.values.to}
                placeholder={t("home.searchFlights.to")}
                icon={ArrivalIcon}
                iconAlt={'arrival'}
                airportsList={airportsList}
              />

              <Button
                onClick={handleSwipeClick}
                className={'home-flight-search-input-swipe-button'}>
                <Image
                  src={SwitchArrows}
                  alt={"switch"}
                  className={"home-flight-search-switch-arrows"}
                  preview={false}
                />
              </Button>
            </>
          }
          {
            state.searchFor === "FlightNumber" &&
            <>
              <FlightSearchInput
                id={'airline'}
                onChange={handleAirlineInputChange}
                value={formikSearchFlight.values.airline}
                placeholder={t("home.searchFlights.airline")}
                airlinesList={airlinesList}
              />

              <FlightSearchInput
                id={'flightNumber'}
                onChange={formikSearchFlight.handleChange}
                value={formikSearchFlight.values.flightNumber}
                placeholder={"1234"}
              />
            </>
          }

          <div className={'home-flight-date-input-container'}>
            <ConfigProvider locale={props.locale}>
              <DatePicker
                onChange={onFlightDateChange}
                className={'home-flight-search-input'}
                format={"DD/MM/YYYY"}
                disabledDate={disabledDate}
              />
            </ConfigProvider>

            <Text className={'home-flight-search-input-label'}>{t("home.searchFlights.departureDate")}</Text>
            {
              state.error &&
              <Text className={"home-flight-error-message"}>*{t("home.searchFlights.errorMessage")}</Text>
            }
          </div>

          <CustomButton
            content={t("home.searchFlights.searchButtonText")}
            clickCallback={formikSearchFlight.submitForm}
            type={'searchFlight'}
            isLoading={state.loading}
          />
        </>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  airportsDuck: state.airportsDuck,
  airlinesDuck: state.airlinesDuck
})

export default connect(mapStateToProps)(FlightSearch)