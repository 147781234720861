import React, { useState } from "react";

// STYLES
import "./Account.css";

// COMPONENTS
import ProfileDataItem from "../../../components/functional_components/profileDataItem/ProfileDataItem";
import CustomButton from "../../../components/functional_components/customButton/CustomButton";
import ProfileToggle from "../../../components/functional_components/profileToggle/ProfileToggle";
import SEO from "../../../components/functional_components/SEO/SEO";

// REDUX
import { connect, useDispatch } from "react-redux";
import { doUpdateData } from "../../../redux/actions/actions";
import { userLogout } from "../../../redux/ducks/UserInfo";

import { useNavigate } from "react-router-dom";
import {
  emailValidation,
  ENVIRONMENT,
  phoneValidation,
} from "../../../utils/properties";
import moment from "moment";
import {
  Col,
  Typography,
  Select,
  Row,
  DatePicker,
  Spin,
  ConfigProvider,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import { returnValue } from "../../../utils/utilities";
import ReactFlagsSelect from "react-flags-select";
import { setLocale } from "../../../utils/utilities";

const Account = (props) => {
  const [state, setState] = useState({
    edit: false,
    userData: props.userDataDuck.userData.data,
    editUserData: null,
    countryUpdate: false,
    loading: false,
  });

  const { t } = useTranslation();
  const { Title } = Typography;
  const navigate = useNavigate();
  const { Option } = Select;
  const dispatch = useDispatch();

  let tempUserData = state.userData;
  let tempEditUserData = state.editUserData;

  const handleClickEdit = () => {
    setState({
      ...state,
      edit: !state.edit,
      editUserData: {
        name: state.userData.name,
        surname: state.userData.surname,
        gender: state.userData.userData.gender,
        birthDate: state.userData.userData.birthDate,
        email: state.userData.email,
        phone: state.userData.phone,
        prefixPhoneCountry: state.userData.prefixPhoneCountry,
        prefixPhone: state.userData.prefixPhone,
        city: state.userData.userData.city,
        address: state.userData.userData.address,
        zipCode: state.userData.userData.zipCode,
        country: state.userData.userData.country
          ? state.userData.userData.country.toUpperCase()
          : null,
        privacyPolicy: state.userData.userLegal.privacyPolicy,
        thirtyPartPolicy: state.userData.userLegal.thirtyPartPolicy,
        marketingPolicy: state.userData.userLegal.marketingPolicy,
        id: props.userInfoDuck.userInfo.id,
        type: props.userInfoDuck.userInfo.type,
        username: props.userDataDuck.userData.data.username,
        source: "SOSTRAVEL",
      },
    });
  };

  const handleClickConfirm = async () => {
    if (tempEditUserData.phone) {
      if (!phoneValidation(tempEditUserData.phone)) {
        return notification.open({
          message: t("general.invalidPhone"),
          top: 120,
          duration: 3,
          className:
            "product-added-notification-message error-notification-message",
        });
      }
    }
    if (tempEditUserData.email) {
      if (!emailValidation(tempEditUserData.email)) {
        return notification.open({
          message: t("general.invalidEmail"),
          top: 120,
          duration: 3,
          className:
            "product-added-notification-message error-notification-message",
        });
      }
    }

    setState({
      ...state,
      loading: true,
    });
    await doUpdateData(tempEditUserData);
    setState({
      ...state,
      edit: !state.edit,
      userData: tempUserData,
      editUserData: tempEditUserData,
      loading: false,
    });
  };

  const handleInputValue = (e) => {
    if (
      e.target.id === "name" ||
      e.target.id === "surname" ||
      e.target.id === "phone" ||
      e.target.id === "email"
    ) {
      tempUserData[e.target.id] = e.target.value;
    } else {
      tempUserData.userData[e.target.id] = e.target.value;
    }
    tempEditUserData[e.target.id] = e.target.value;
  };

  const handleCountryChange = (value) => {
    tempUserData.userData["country"] = value.toUpperCase();
    tempEditUserData.country = value.toUpperCase();

    setState({
      ...state,
      countryUpdate: !state.countryUpdate,
    });
  };

  const handleLogout = () => {
    dispatch(userLogout());
    navigate(ENVIRONMENT.ROUTING.BASE_URL);
  };

  const handleGenderChange = (value) => {
    tempUserData.userData["gender"] = value;
    tempEditUserData.gender = value;
  };

  const prefixHandleChange = (value, prefixPhoneCountry) => {
    tempUserData.userData["prefixPhone"] = value;
    tempEditUserData.prefixPhone = value;
    tempUserData.userData["prefixPhoneCountry"] = prefixPhoneCountry;
    tempEditUserData.prefixPhoneCountry = prefixPhoneCountry;
  };

  const handleBirthDate = (date) => {
    tempUserData.userData["birthDate"] = moment(date).format("YYYY-MM-DD");
    tempEditUserData.birthDate = moment(date).format("YYYY-MM-DD");
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleToggleChange = (value) => () => {
    tempUserData.userLegal[value] = !tempUserData.userLegal[value];
    tempEditUserData[value] = !tempEditUserData[value];
  };

  return (
    <Col className={"profile-account-container"}>
      <SEO page={"Profile"} url={"profile"} />

      <Col className={"profile-account-title-desktop"}>
        <Title>{t("profile.myData")}</Title>
        <div className={"profile-account-title-desktop-line"} />
      </Col>
      {!state.userData && (
        <div className={"profile-account-loader-container centered"}>
          <Spin size={"large"} />
        </div>
      )}
      {state.userData && (
        <>
          <Col className={"profile-account-input-row-responsive"}>
            <ProfileDataItem
              id={"name"}
              label={t("general.name")}
              value={returnValue(state.userData, "name")}
              disabled={!state.edit}
              callback={handleInputValue}
            />
            <ProfileDataItem
              id={"surname"}
              label={t("general.surname")}
              value={returnValue(state.userData, "surname")}
              disabled={!state.edit}
              callback={handleInputValue}
            />
            {props.userInfoDuck.userInfo.type === "B2C" && (
              <>
                <Col className={"profile-account-input-gender-container"}>
                  <div className={"profile-account-input-country-label"}>
                    {t("general.gender")}
                  </div>
                  <Select
                    defaultValue={returnValue(
                      state.userData.userData,
                      "gender"
                    )}
                    style={{ width: 120 }}
                    onChange={handleGenderChange}
                    disabled={!state.edit}
                  >
                    <Option value="M">{t("general.male")}</Option>
                    <Option value="F">{t("general.female")}</Option>
                  </Select>
                </Col>

                <Col className={"profile-account-input-country-container"}>
                  <div className={"profile-account-input-country-label"}>
                    {t("general.birthday")}
                  </div>
                  <ConfigProvider
                    locale={setLocale(props.languageDuck.currentLanguage)}
                  >
                    <DatePicker
                      onChange={handleBirthDate}
                      className={"profile-account-input-date-picker"}
                      disabled={!state.edit}
                      placeholder={
                        returnValue(state.userData.userData, "birthDate") !==
                        "-"
                          ? moment(
                              returnValue(state.userData.userData, "birthDate")
                            ).format("DD/MM/YYYY")
                          : returnValue(state.userData.userData, "birthDate")
                      }
                      disabledDate={disabledDate}
                      format={"DD/MM/YYYY"}
                    />
                  </ConfigProvider>
                </Col>
              </>
            )}
          </Col>
          <Col className={"profile-account-input-row-responsive"}>
            <ProfileDataItem
              id={"email"}
              label={t("general.email")}
              value={returnValue(state.userData, "email")}
              disabled={!state.edit}
              callback={handleInputValue}
            />

            {props.userInfoDuck.userInfo.type === "B2C" && (
              <ProfileDataItem
                id={"phone"}
                label={t("general.phoneNumber")}
                value={returnValue(state.userData, "phone")}
                disabled={!state.edit}
                callback={handleInputValue}
                prefixHandleChange={prefixHandleChange}
                prefix
              />
            )}
          </Col>
          {props.userInfoDuck.userInfo.type !== "B2C" && (
            <>
              <ProfileDataItem
                id={"role"}
                label={t("general.role")}
                value={props.userDataDuck.userData.data.type}
                disabled
              />
              <ProfileDataItem
                id={"agency"}
                label={t("general.agency")}
                value={props.userDataDuck.userData.data.network?.name}
                disabled
              />
            </>
          )}
          {props.userInfoDuck.userInfo.type === "B2C" && (
            <>
              <Col className={"profile-account-input-row-responsive"}>
                <ProfileDataItem
                  id={"city"}
                  label={t("general.city")}
                  value={returnValue(state.userData.userData, "city")}
                  disabled={!state.edit}
                  callback={handleInputValue}
                />
                <ProfileDataItem
                  id={"address"}
                  label={t("general.address")}
                  value={returnValue(state.userData.userData, "address")}
                  disabled={!state.edit}
                  callback={handleInputValue}
                />
              </Col>
              <Col className={"profile-account-input-row-responsive"}>
                <ProfileDataItem
                  id={"zipCode"}
                  label={t("general.zipCode")}
                  value={returnValue(state.userData.userData, "zipCode")}
                  disabled={!state.edit}
                  callback={handleInputValue}
                  style={{ width: "120px" }}
                />
                <Col className={"profile-account-input-country-container"}>
                  <div className={"profile-account-input-country-label"}>
                    {t("general.country")}
                  </div>
                  <ReactFlagsSelect
                    selected={
                      state.userData.userData.country
                        ? state.userData.userData.country.toUpperCase()
                        : null
                    }
                    onSelect={handleCountryChange}
                    searchable
                    selectedSize={14}
                    optionsSize={14}
                    fullWidth={false}
                    className={
                      !state.edit
                        ? "login-input-country-select-disabled"
                        : "login-input-country-select"
                    }
                    disabled={!state.edit}
                    placeholder={t("general.selectCountry")}
                  />
                </Col>
              </Col>
            </>
          )}
        </>
      )}
      {props.userInfoDuck.userInfo.type === "B2C" && (
        <div className={"profile-account-input-button-responsive"}>
          {!state.edit && (
            <CustomButton
              type={"primary-xs"}
              content={t("general.edit")}
              clickCallback={handleClickEdit}
            />
          )}
          {state.edit && (
            <CustomButton
              type={"primary-xs-confirm"}
              content={t("general.save")}
              clickCallback={handleClickConfirm}
              isLoading={state.loading}
            />
          )}
        </div>
      )}

      <Col className={"profile-account-logout-button-container"}>
        <div className={"profile-account-logout-button-h-line"} />
        <CustomButton
          content={t("profile.dropdownLinks.logout")}
          type={"transparent-lg"}
          style={{ width: "200px", marginLeft: "calc(50% - 100px)" }}
          clickCallback={handleLogout}
        />
      </Col>

      {state.userData && props.userInfoDuck.userInfo.type === "B2C" && (
        <Col className={"profile-layout-toggles-container"}>
          <Row className={"profile-layout-toggles-row-container"}>
            <ProfileToggle
              title={t("profile.toggles.toggle1.title")}
              description={t("profile.toggles.toggle1.description")}
              onChange={handleToggleChange("privacyPolicy")}
              disabled={!state.edit}
              defaultChecked={state.userData.userLegal.privacyPolicy}
            />
            <ProfileToggle
              title={t("profile.toggles.toggle2.title")}
              description={t("profile.toggles.toggle2.description")}
              onChange={handleToggleChange("thirtyPartPolicy")}
              disabled={!state.edit}
              defaultChecked={state.userData.userLegal.thirtyPartPolicy}
            />
          </Row>
          <Row className={"profile-layout-toggles-row-container"}>
            <ProfileToggle
              title={t("profile.toggles.toggle3.title")}
              description={t("profile.toggles.toggle3.description")}
              onChange={handleToggleChange("marketingPolicy")}
              disabled={!state.edit}
              defaultChecked={state.userData.userLegal.marketingPolicy}
            />
            <ProfileToggle
              title={t("profile.toggles.toggle4.title")}
              description={t("profile.toggles.toggle4.description")}
              disabled
              defaultChecked
            />
          </Row>
        </Col>
      )}
    </Col>
  );
};

const mapStateToProps = (state) => ({
  userDataDuck: state.userDataDuck,
  userInfoDuck: state.userInfoDuck,
  languageDuck: state.languageDuck,
});

export default connect(mapStateToProps)(Account);
