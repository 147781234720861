import React, { useState } from "react";

// STYLES
import './LangModal.css';

// COMPONENTS
import LangModalContent from "../../functional_components/langModalContent/LangModalContent";

import { Modal, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const LangModal = (props) => {

  const [state, setState] = useState({
    menuSelect: "lang"
  })

  const { t } = useTranslation();

  const handleClick = (e) => {
    setState({
      ...state,
      menuSelect: e.key
    })
  }

  return (
    <Modal
      title={"Basic Modal"}
      visible={props.visible}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      className={"login-access-type-menu-container modal-lang-container"}
    >
      <Menu
        mode={"horizontal"}
        onClick={handleClick}
        defaultSelectedKeys={"lang"}
      >
        <Menu.Item
          key={"lang"}
          style={state.menuSelect === 'lang' ? { color: 'var(--main-black)' } : { color: 'var(--secondary-gray)' }}
        >
          {t("general.language")}
        </Menu.Item>
        <Menu.Item
          key={"currency"}
          style={state.menuSelect === 'currency' ? { color: 'var(--main-black)' } : { color: 'var(--secondary-gray)' }}
        >
          {t("general.currency")}
        </Menu.Item>
      </Menu>

      {
        state.menuSelect === "lang" &&
        <LangModalContent
          type={"lang"}
          title={t("languageModal.chooseLanguage")}
          defaultValue={props.languageDuck.currentLanguage.substring(0,2)}
        />
      }

      {
        state.menuSelect === "currency" &&
        <LangModalContent
          type={"currency"}
          title={t("languageModal.chooseCurrency")}
          defaultValue={props.currencyDuck.currency}
        />
      }
    </Modal>
  )
}

const mapStateToProps = state => ({
  languageDuck: state.languageDuck,
  currencyDuck: state.currencyDuck
})

export default connect(mapStateToProps)(LangModal)