const SET_AIRPORTS = 'SET_AIRPORTS'
const INIT_AIRPORTS = 'INIT_AIRPORTS'

export function setAirports(value) {
  return {
    type: SET_AIRPORTS,
    payload: {
      airports: value
    }
  };
}

export function initAirports() {
  return {
    type: INIT_AIRPORTS,
    payload: {
      airports: {}
    }
  }
}

const INIT_STATE = {
  airports: {}
}

export default function airportsDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_AIRPORTS:
      newState.airports = action.payload.airports;
      break;
    case INIT_AIRPORTS:
      newState.airports = {};
      break;
    default:
      break;
  }
  return newState;
}