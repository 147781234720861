import React from "react";

// STYLES
import './FlightsListItem.css';

// ASSETS
import FlightItemIcon from '../../../assets/images/searchFlight/flight-item-icon.svg';
import DottedLine from '../../../assets/images/searchFlight/dotted-line.svg';

import { Col, Image, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";

const FlightsListItem = (props) => {

  const { Text } = Typography;
  const { t } = useTranslation();

  const stopovers = () => {
    let connections;
    switch (props.stopovers) {
      case (0):
        connections = t("home.searchFlights.direct");
        break;
      case (1):
        connections = '1' + t("home.searchFlights.stepover");
        break;
      case (2):
        connections = '2' + t("home.searchFlights.stepover");
        break;
      default:
        connections = '2+' + t("home.searchFlights.stepover");
        break;
    }
    return connections;
  }

  return (
    <Col className={'flights-list-item-container'}>
      <Row className={'flights-list-item-time-travel-container'}>
        <Col className={'flights-list-item-time-travel-text-container'}>
          <Text className={'flights-list-item-travel-date'}>{props.departureDate}</Text>
          <Text className={'flights-list-item-time-travel-hours'}>{props.departureHours}</Text>
          <Text className={'flights-list-item-time-travel-airport'}>{props.departureAirport}</Text>
        </Col>

        <Col className={'flights-list-item-time-travel-text-container'}>
          <Image
            src={FlightItemIcon}
            alt={'flight-item-icon'}
            preview={false}
          />
          <Text className={'flights-list-item-time-travel-airport'}>{props.flightDuration}</Text>
        </Col>

        <Col className={'flights-list-item-time-travel-text-container'}>
        <Text className={'flights-list-item-travel-date'}>{props.arrivalDate}</Text>
          <Text className={'flights-list-item-time-travel-hours'}>{props.arrivalHours}</Text>
          <Text className={'flights-list-item-time-travel-airport'}>{props.arrivalAirport}</Text>
        </Col>
      </Row>

      <Image
        src={DottedLine}
        alt={'dotted-line'}
        preview={false}
      />

      <Col className={'flights-list-item-airline-container'}>
        <Row className={'flights-list-item-detail-travel-text-container'}>
          <Text>{stopovers()}</Text>
          <Text>{props.flightCode}</Text>
        </Row>
        <Row className={'flights-list-item-airline-text-container'}>
          <Image
            src={props.airlineIcon}
            alt={'airline-icon'}
            preview={false}
          />
          <Text>{props.airline}</Text>
        </Row>
      </Col>

    </Col>
  )
}

export default FlightsListItem