import React from "react";

// STYLES
import './HomeServiceContent.css';

import { Col, Typography } from "antd";
import { useTranslation } from "react-i18next";

const HomeServiceContent = (props) => {

  const { Text, Title } = Typography;
  const { t } = useTranslation();

  const getTarget = () => {
    let target;
    switch (props.target) {
      case ("PERSON"):
        target = <span> / {t(`home.servicesCarousel.target.person`)}</span>;
      break;
      case ("BAGGAGE"):
        target = <span> / {t(`home.servicesCarousel.target.luggage`)}</span>;
      break;
      default:
        target = <span> / {t(`home.servicesCarousel.target.person`)}</span>;
      break;
    }

    return target;
  }

  return (
    <div>
      <Title className={'home-service-carousel-card-title'}>
        {props.cardTitle}
      </Title>

      <Text className={'home-service-carousel-card-subtitle'} style={{ color: props.subtitleColor }}>
        {props.cardSubtitle}
      </Text> <br />

      {
        !props.noDescription &&
        <Col className={'home-service-carousel-card-description-container'}>
          <Text className={'home-service-carousel-card-description'} style={{ color: props.descriptionColor }}>
            {props.cardDescription}
          </Text>
        </Col>
      }


      <div
        className={'home-service-carousel-card-price-text'}
        style={props.siderMenu ? { marginTop: 0 } : {}}
      >
        <Text className={'home-service-carousel-card-price'}>
          {props.currency} {props.priceForItem}
        </Text>
        {
          !props.siderMenu &&
          getTarget()
        }
      </div>
    </div>
  )
}

export default HomeServiceContent