const SET_PAYMENTTOKEN = 'SET_PAYMENTTOKEN'
const INIT_PAYMENTTOKEN = 'INIT_PAYMENTTOKEN'

export function setPaymentToken(value) {
  return {
    type: SET_PAYMENTTOKEN,
    payload: {
      paymentToken: value
    }
  };
}

export function initPaymentToken() {
  return {
    type: INIT_PAYMENTTOKEN,
    payload: {
      paymentToken: {}
    }
  }
}

const INIT_STATE = {
  paymentToken: {}
}

export default function paymentTokenDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_PAYMENTTOKEN:
      newState.paymentToken = action.payload.paymentToken;
      break;
    case INIT_PAYMENTTOKEN:
      newState.paymentToken = {};
      break;
    default:
      break;
  }
  return newState;
}