import React, { useEffect, useState } from "react";

// STYLES 
import './ProfileHeaderNavbar.css';

// ASSETS
import ProfileIcon from '../../../assets/images/profile/profile-default-icon.png';

// REDUX
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../../redux/ducks/UserInfo";
import { connect, useDispatch } from "react-redux";
import { setUserData } from "../../../redux/ducks/UserData";

import { useTranslation } from "react-i18next";
import { Button, Row, Typography, Col, Upload, message, Image } from "antd";
import { ENVIRONMENT } from "../../../utils/properties";
import { get } from "lodash";
import { useLocation } from "react-router-dom";


const ProfileHeaderNavbar = (props) => {

  const { Text } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    routePath: location.pathname.replace("/profile", ""),
    loadingPhoto: false,
    imageUrl: ProfileIcon,
    profileName: "User"
  })

  useEffect(() => {
    if (props.userDataDuck.userData) {

      let name = "";
      if (!props.userDataDuck.userData.data.name && !props.userDataDuck.userData.data.surname) {
        name = get(props.userDataDuck.userData.data, "email", "-");
      } else if (!props.userDataDuck.userData.data.name && props.userDataDuck.userData.data.surname) {
        name = props.userDataDuck.userData.data.surname;
      } else if (props.userDataDuck.userData.data.name && !props.userDataDuck.userData.data.surname) {
        name = props.userDataDuck.userData.data.name;
      } else {
        name = `${props.userDataDuck.userData.data.name} ${props.userDataDuck.userData.data.surname}`
      }

      setState({
        ...state,
        imageUrl: props.userDataDuck.userData.data.userSettings.photo,
        profileName: name,
        routePath: location.pathname.replace("/profile", "")
      });
    }
  }, [props.userDataDuck.userData, location])

  const handleClick = (value) => () => {
    let routePath;

    switch (value) {
      case 0:
        routePath = "";
        break;
      case 1:
        routePath = "/orders";
        break;
      case 2:
        routePath = "/promotions";
        break;
      case 3:
        routePath = "/paymentMethods";
        break;
      default:
        routePath = "";
        break;
    }

    setState({
      ...state,
      routePath: routePath
    })
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}profile${routePath}`)
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(t("profile.desktopNavbar.invalidFileType"));
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t("profile.desktopNavbar.invalidFileSize"));
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChangeAvatar = (info) => {
    if (info.file.status === 'uploading') {
      setState({
        ...state,
        loading: true
      });
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        let userData = props.userDataDuck.userData;
        userData.data.userSettings.photo = info.file.response;
        dispatch(setUserData(userData))
        setState({
          ...state,
          imageUrl,
          loading: false,
        });
      });

    }
  }

  const handleClickLogout = () => {
    dispatch(userLogout());
    navigate(ENVIRONMENT.ROUTING.BASE_URL);
  }

  return (
    <Row className={"profile-header-navbar-container"}>
      <Col className={"profile-header-navbar-photo-container"}>
        <Text className={"profile-header-navbar-photo-name"}>{state.profileName}</Text>
        <Upload
          name={'photo'}
          listType={"picture-card"}
          showUploadList={false}
          key={'photo'}
          action={`${ENVIRONMENT.API_REST_BE}userauth/user/upload-photo`}
          headers={{ "Authorization": `Bearer ${props.userInfoDuck.userInfo.token}` }}
          beforeUpload={beforeUpload}
          onChange={handleChangeAvatar}
          accept={".png, .jpeg"}
          className={"profile-header-navbar-photo-frame"}
        >
          <Image
            src={state.imageUrl ? state.imageUrl : ProfileIcon}
            alt={" "}
            style={{ width: '100%' }}
            preview={false}
          />
          <Button
            className={"profile-header-navbar-photo-edit"}
          >+</Button>
        </Upload>

        <Col className={"profile-header-navbar-photo-description-text"}>
          <Text>{t('profile.desktopNavbar.editPhoto')}</Text>
          <Text>{t('profile.desktopNavbar.userLogin')}: {get(props.userDataDuck.userData.data, "username", "-")}</Text>
        </Col>

      </Col>
      <div
        onClick={handleClick(0)}
        className={state.routePath === "" ? "profile-header-navbar-text-active" : "cursor-pointer"}
      >
        {t('profile.navbarLinks.account')}
      </div>
      <div
        onClick={handleClick(1)}
        className={state.routePath === "/orders" ? "profile-header-navbar-text-active" : "cursor-pointer"}
      >
        {t('profile.navbarLinks.orders')}
      </div>
      {
        props.userInfoDuck.userInfo.type === "B2C" &&
        <div
          onClick={handleClick(2)}
          className={state.routePath === "/promotions" ? "profile-header-navbar-text-active" : "cursor-pointer"}
        >
          {t('profile.navbarLinks.promotions')}
        </div>
      }
      {
        props.userInfoDuck.userInfo.type === "B2B_ADMIN" &&
        <div
          onClick={handleClick(3)}
          className={state.routePath === "/paymentMethods" ? "profile-header-navbar-text-active" : "cursor-pointer"}
        >
          {t('profile.paymentMethods')}
        </div>
      }

      <div className={'profile-header-navbar-logout-text'} onClick={handleClickLogout}>{t("profile.dropdownLinks.logout")}</div>
    </Row>
  )
}

const mapStateToProps = state => ({
  userDataDuck: state.userDataDuck,
  userInfoDuck: state.userInfoDuck
})

export default connect(mapStateToProps)(ProfileHeaderNavbar)