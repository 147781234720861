import React from "react";

// STYLES
import './ThankYou.css';

// COMPONENTS
import HowToCarousel from "../../components/functional_components/howToCarousel/HowToCarousel";
import HeaderHome from "../../components/functional_components/headerHome/HeaderHome";
import MobileNavbar from "../../components/hooks_components/mobileNavbar/MobileNavbar";
import SEO from "../../components/functional_components/SEO/SEO";

import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const ThankYou = () => {

  const { Text, Title } = Typography;
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Col>
      <SEO
        page={"ThankYou"}
        url={"thank-you"}
      />

      <Row className={"thank-you-page-header-container"}>
        <HeaderHome dark />
        <div className={"thank-you-page-h-line"} style={{ width: "96%", margin: "0 auto" }} />
      </Row>

      <Col className={"thank-you-page-container"}>

        <Col className={"thank-you-page-header-title-container"}>
          <Title>{t("thankyouPage.title")}</Title>
          <Text>{t("thankyouPage.subtitle")}</Text>
        </Col>

        {
          location.state.services.map((service, i) => {
            return (
              <div key={i}>
                <HowToCarousel
                  productDetail={service}
                  thankyou
                />
              </div>
            )
          })
        }

      </Col>
      <MobileNavbar />
    </Col>
  )
}

export default ThankYou