import React from "react";

// STYLES
import './LoginLayout.css'

// COMPONENTS
import SiderLogin from "../../components/functional_components/siderLogin/SiderLogin";
import HeaderLogin from "../../components/functional_components/headerLogin/HeaderLogin";

import { Outlet } from "react-router";
import { Layout } from "antd";

const { Header, Content, Sider } = Layout

const LoginLayout = (props) => {

  return (
    <Layout className='min-h-100vh'>
      <Sider
        width={'400px'}
        className={'loginLayout-sider'}
        breakpoint={'lg'}
        collapsedWidth={0}
      >
        <SiderLogin />
      </Sider>
      <Layout>
        <Header style={{ height: '110px', padding: 0 }}>
          <HeaderLogin page={props.page} />
        </Header>
        <Content style={{ backgroundColor: '#FFFFFF', padding: 20 }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default LoginLayout