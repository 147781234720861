import React from "react";
import FooterCard from "../footerCard/FooterCard";

// STYLES
import './FooterCheckout.css';

import { Col, Row } from "antd";
import { connect } from "react-redux";
import { getValueFromLang } from "../../../utils/utilities";
import { get } from "lodash";

const FooterCheckout = (props) => {

  return (
    <Col className={"footer-checkout-container"}>
      <Col className={"footer-checkout-responsive-content-container"}>
        <Row className={"footer-checkout-content-container"}>
          {
            get(props.productsListDuck.productsList, "data", []).map((product, index) => {
              if (product.b2c) {
                return (
                  <div key={index} className={"footer-checkout-card-container"}>
                    <FooterCard
                      badge={product.iconImage}
                      title={getValueFromLang(product.name, props.languageDuck.currentLanguage)}
                      content1={getValueFromLang(product.checkoutTip, props.languageDuck.currentLanguage)}
                    />
                  </div>
                )
              }
            })
          }
        </Row>
      </Col>
    </Col>
  )
}

const mapStateToProps = state => ({
  productsListDuck: state.productsListDuck,
  homeDataDuck: state.homeDataDuck,
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(FooterCheckout)