import React, { useState } from "react";

// STYLES
import './FooterHome.css';

// ASSETS
import Globe from '../../../assets/images/footer/footer-globe.svg';
import Apple from '../../../assets/images/footer/footer-apple-store.svg';
import PlayStore from '../../../assets/images/footer/footer-play-store.svg';
import Logo from '../../../assets/images/logo/logo-sos-travel-white.svg';

// COMPONENTS
import FooterCard from "../footerCard/FooterCard";
import LangModal from "../../hooks_components/langModal/LangModal";

import { ENVIRONMENT } from '../../../utils/properties';
import { useTranslation } from 'react-i18next';
import { Typography, Col, Row, Image } from "antd";
import { connect } from "react-redux";

const FooterHome = (props) => {

  const [state, setState] = useState({
    isModalVisible: false
  })

  const { t } = useTranslation();
  const { Text, Link } = Typography;

  const storeButton = (value) => () => {
    if (value === "apple") {
      window.open("https://apps.apple.com/it/app/sostravel/id1068419699", "_blank");
    }
    if (value === "play") {
      window.open("https://play.google.com/store/apps/details?id=com.safebag.safebag24&hl=it", "_blank");
    }
  }

  const showModal = () => {
    setState({
      ...state,
      isModalVisible: true
    })
  }

  const handleCancel = () => {
    setState({
      ...state,
      isModalVisible: false
    })
  }

  const langValue = (item) => {
    let value = '';
    let investorUrl = '';
    switch (props.languageDuck.currentLanguage) {
      case ("IT"):
        value = "Italiano (IT)";
        investorUrl = "https://www.sostravel.com/investor-relators/";
        break;
      case ("EN"):
        value = "English (EN)";
        investorUrl = "https://www.sostravel.com/en/investor-relators-eng/";
        break;
      case ("PT"):
        value = "Português (PT)";
        investorUrl = "https://www.sostravel.com/en/investor-relators-eng/";
        break;
      case ("ES"):
        value = "Español (ES)";
        investorUrl = "https://www.sostravel.com/en/investor-relators-eng/";
        break;
      case ("RU"):
        value = "русский (RU)";
        investorUrl = "https://www.sostravel.com/en/investor-relators-eng/";
        break;
      case ("FR"):
        value = "Français (FR)";
        investorUrl = "https://www.sostravel.com/en/investor-relators-eng/";
        break;
      default:
        value = "Language";
        investorUrl = "https://www.sostravel.com/en/investor-relators-eng/";
        break;
    }
    switch (item) {
      case ("lang"):
        return value;
      case ("investor"):
        return window.open(investorUrl, "_blank");
    }
  }

  const currValue = () => {
    let curr = '';
    switch (props.currencyDuck.currency) {
      case ("EUR"):
        curr = "€ - EUR";
        break;
      case ("USD"):
        curr = "$ - USD";
        break;
      default:
        curr = "€ - EUR";
        break;
    }
    return curr;
  }

  const checkProd = (prod) => {
    let checked = false;
    props.productsListDuck.productsList.data.map((product) => {
      if (prod === product.code) {
        checked = true;
      }
    })
    return checked;
  }

  return (
    <Col className={'footer-container'}>
      <LangModal
        visible={state.isModalVisible}
        handleCancel={handleCancel}
      />

      <Col className={'footer-description-cards-container'}>
        <Col className={'footer-description-container'}>
          <Link href={ENVIRONMENT.ROUTING.BASE_URL} className={'footer-description-logo'}>
            <Image
              src={Logo}
              alt={'sostravel logo'}
              preview={false}
            />
          </Link>
          <div className={'footer-description-text-container'}>
            <Text className={'footer-description-text'}>
              {t('footer.description')}
            </Text>
          </div>
          <Text className={'footer-description-investor-relations'} onClick={() => langValue("investor")}>
            {t("footer.investorRelations")}
          </Text>
        </Col>
        <Col className={'footer-cards-container'}>
          {
            !props.loading &&
            <FooterCard
              title={t('footer.cards.card1.title')}
              content1={checkProd("LLC") ? t('footer.cards.card1.content1') : null}
              href1={"LLC-detail"}
              content2={checkProd("TLDRST") ? t('footer.cards.card1.content2') : null}
              href2={"DrTravel-detail"}
              content3={checkProd("VIPLOUNGE") ? t('footer.cards.card1.content3') : null}
              href3={"VIPLOUNGE-detail"}
              // content4={t('footer.cards.card1.content4')}
              // href4={"travel-insurance"}
            />
          }
          <FooterCard
            title={t('footer.cards.card2.title')}
            content1={t('footer.cards.card2.content1')}
            href1={t('footer.cards.card2.href1', { baseUrl: ENVIRONMENT.BASE_URL })}
            externalLink1
            content2={t('footer.cards.card2.content2')}
            href2={t('footer.cards.card2.href2', { baseUrl: ENVIRONMENT.BASE_URL })}
            content3={t('footer.cards.card2.content3')}
            href3={t('footer.cards.card2.href3', { baseUrl: ENVIRONMENT.BASE_URL })}
            content4={t('footer.cards.card2.content4')}
            href4={t('footer.cards.card2.href4', { baseUrl: ENVIRONMENT.BASE_URL })}
            externalLink4
          />
          <FooterCard
            title={t('footer.cards.card3.title')}
            content1={t('footer.cards.card3.content1')}
            href1={t('footer.cards.card3.href1', { baseUrl: ENVIRONMENT.BASE_URL })}
            externalLink1
            content2={t('footer.cards.card3.content2')}
            href2={t('footer.cards.card3.href2', { baseUrl: ENVIRONMENT.BASE_URL })}
            externalLink2
            content3={t('footer.cards.card3.content3')}
            href3={t('footer.cards.card3.href3', { baseUrl: ENVIRONMENT.BASE_URL })}
            externalLink3
          />
        </Col>
      </Col>

      <Col className={"centered"}>
        <Row className={'footer-separator-line'} />
      </Col>

      <Col className={'footer-bottom-container'}>
        <Text className={'footer-reserved-rights-text'}>{t('footer.reservedRights')}</Text>

        <Row className={"footer-buttons-container-container"}>
          <Row className={"footer-policy-button-container"}>
            <a href={t('footer.privacyPolicyHref')} target="_blank" rel="noreferrer" className={'footer-policy-button'}>{t('footer.privacyPolicy')}</a>
            <a href={t('footer.cookiePolicyHref')} target="_blank" rel="noreferrer" className={'footer-policy-button'}>{t('footer.cookiePolicy')}</a>
          </Row>

          <Row className={"footer-terms-buttons-container"}>
            <a href={t('footer.termsAndConditionsHref')} target="_blank" rel="noreferrer" className={'footer-terms-button'}>{t('footer.termsAndConditions')}</a>
          </Row>
        </Row>

        <Row className={'footer-bottom-buttons-container'}>
          <Row className={'footer-bottom-buttons-lang'} onClick={showModal}>
            <Image
              src={Globe}
              alt={'globe'}
              preview={false}
            />
            <Text className={'footer-bottom-buttons-lang-text'}>{langValue("lang")}</Text>
            <Text>{currValue()}</Text>
          </Row>
          <Row className={'footer-bottom-buttons-store'}>
            <Image
              src={Apple}
              alt={'apple store'}
              onClick={storeButton('apple')}
              preview={false}
            />
            <Image
              src={PlayStore}
              alt={'play store'}
              onClick={storeButton('play')}
              preview={false}
            />
          </Row>
        </Row>
      </Col>
    </Col>
  )
}

const mapStateToProps = state => ({
  languageDuck: state.languageDuck,
  currencyDuck: state.currencyDuck,
  productsListDuck: state.productsListDuck
})

export default connect(mapStateToProps)(FooterHome)