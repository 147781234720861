import React from "react";

// STYLES
import '../Checkout/PreCheckout/PreCheckout.css';

// COMPONENTS
import CustomButton from "../../components/functional_components/customButton/CustomButton";
import CheckoutServiceCard from "../../components/functional_components/checkoutServiceCard/CheckoutServiceCard";
import B2bFooter from "../../components/functional_components/b2bFooter/B2bFooter";
import SEO from "../../components/functional_components/SEO/SEO";

// REDUX
import { connect } from "react-redux";

import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../utils/properties";
import { formatValue, getValueFromLang } from "../../utils/utilities";

const B2B_Home = (props) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}B2B/checkout`);
  }

  const getPrice = (service) => {
    let productPrice;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        product.prices.map((price) => {
          if (price.currency === props.currencyDuck.currency) {
            productPrice = price.price;
          }
        });
      }
    });
    return productPrice;
  }

  const getBadge = (service) => {
    let badge;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        badge = product.iconImage
      }
    });
    return badge;
  }


  return (
    <Col>
      <SEO
        page={"B2B_Home"}
        url={"B2B/home"}
      />

      <Row className={"pre-checkout-cards-container b2b-pre-checkout-cards-container"}>
        {
          props.productsListDuck.productsList.data.map((service, index) => {
            if (service.b2b) {
              return (
                <div key={index}>
                  <CheckoutServiceCard
                    cardCode={service.code}
                    cardTitle={getValueFromLang(service.name, props.languageDuck.currentLanguage)}
                    cardSubtitle={getValueFromLang(service.subtitle, props.languageDuck.currentLanguage)}
                    cardDescription={getValueFromLang(service.cardDescription, props.languageDuck.currentLanguage)}
                    priceForItem={formatValue(getPrice(service.code))}
                    target={service.target}
                    badge={getBadge(service.code)}
                    currency={props.currencyDuck.currency === "EUR" ? "€" : "$"}
                    noRemove
                  />
                </div>
              )
            }
          })
        }
      </Row>

      <div className={"pre-checkout-content-button-container"}>
        <CustomButton
          type={'primary'}
          content={t("checkout.goToCheckout")}
          isDisabled={props.servicesDuck.services.length === 0}
          style={{
            width: '288px',
            height: '48px',
            fontSize: "16px"
          }}
          clickCallback={handleClick}
        />
      </div>

      <B2bFooter />
    </Col>
  )
}

const mapStateToProps = state => ({
  availableServicesDuck: state.availableServicesDuck,
  productsListDuck: state.productsListDuck,
  currencyDuck: state.currencyDuck,
  servicesDuck: state.servicesDuck,
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(B2B_Home)