import React, { useEffect, useState } from 'react';

// STYLES
import './CheckoutServices.css';

// ASSETS
import Info from '../../../assets/images/checkout/info-promo.svg';
import Arrow from '../../../assets/images/checkout/down-arrow.svg';
import Bucket from '../../../assets/images/checkout/delete-item-icon.svg';

// COMPONENTS
import FooterCheckout from '../../../components/functional_components/footerCheckout/FooterCheckout';
import CheckoutServicesBox from '../../../components/functional_components/checkoutServicesBox/CheckoutServicesBox';
import CheckoutServiceCard from '../../../components/functional_components/checkoutServiceCard/CheckoutServiceCard';

// REDUX
import { connect, useDispatch } from 'react-redux';
import { activatePromotion, calculatePromotion, getPaymentToken, getPromo } from '../../../redux/actions/actions';
import { setDiscount } from '../../../redux/ducks/Discount';

import { Col, Row, Image, Typography, Select, Input, Button, notification} from 'antd';
import { useTranslation } from 'react-i18next';
import { formatValue, getValueFromLang } from '../../../utils/utilities';
import { useFormik } from 'formik';
import logo from '../../../assets/images/logo/notext-logo.svg';

const CheckoutServices = (props) => {
	const [state, setState] = useState({
		promo: [],
		promoSelected: 0,
		promoCodeLoading: false,
		promoCodeValid: null,
		promoLoading: false,
	});

	const [loading, setLoading] = useState(false);

	const { Text, Title } = Typography;
	const { Option } = Select;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		getPromotions();
		paymentToken();
	}, []);

	useEffect(() => {
		setLoading(true);
		refreshPromoFix();
	}, [props.servicesDuck]);

	const refreshPromoFix = async () => {
		let selectedValue = state.promoSelected;
		if (selectedValue !== 'promocode') {
			selectedValue = selectedValue - 1;
		}
		if (state.promo[selectedValue]?.promotionCode) {
			let promoData = {
				promotionCode: state.promo[selectedValue].promotionCode,
				currency: props.currencyDuck.currency,
				items: props.servicesDuck.services.map((service) => {
					let data = [];
					for (let i = 0; i < service.value; i++) {
						data.push({});
					}
					let productId;
					props.productsListDuck.productsList.data.map((product) => {
						if (product.code === service.name) {
							productId = product.id;
						}
					});
					return {
						productId: productId,
						productCode: service.name,
						quantity: service.value,
						data: data,
					};
				}),
			};

			let result = await calculatePromotion(promoData);
			if (result) {
				dispatch(setDiscount({ code: state.promo[selectedValue].promotionCode, amount: result.finalPrice }));
			}
		}
		setLoading(false);
	};

	const paymentToken = async () => {
		let paymentCurr = props.currencyDuck.currency;
		await getPaymentToken(dispatch, paymentCurr);
	};

	let promoList;

	const getPromotions = async () => {
		promoList = await getPromo();
		setState({
			...state,
			promo: promoList.response,
		});
	};

	const formikPromoCode = useFormik({
		initialValues: {
			promoCode: '',
		},
		onSubmit: (values) => {
			promoCodeConfirm(values);
		},
	});

	const promoCodeConfirm = async (value) => {
		if (value.promoCode) {
			setState({
				...state,
				promoCodeLoading: true,
			});
			let promoData = {
				code: value.promoCode,
			};
			let calculatePromoData = {
				promotionCode: value.promoCode,
				currency: props.currencyDuck.currency,
				items: props.servicesDuck.services.map((service) => {
					let data = [];
					for (let i = 0; i < service.value; i++) {
						data.push({});
					}
					let productId;
					props.productsListDuck.productsList.data.map((product) => {
						if (product.code === service.name) {
							productId = product.id;
						}
					});
					return {
						productId: productId,
						productCode: service.name,
						quantity: service.value,
						data: data,
					};
				}),
			};

			let response = await activatePromotion(promoData);
			if (!response.success) {
				notification.open({
					message: 'Il codice non è corretto',
					top: 120,
					duration: 3,
					className: 'product-added-notification-message error-notification-message',
				});
				return setState({
					...state,
					promoCodeLoading: false,
					promoSelected: 0,
				});
			}
			let result = await calculatePromotion(calculatePromoData);
			if (result) {
				dispatch(setDiscount({ code: value.promoCode, amount: result.finalPrice }));
			}
			setState({
				...state,
				promoCodeLoading: false,
				promoSelected: value.promoCode,
			});
		}
	};

	const getPrice = (service) => {
		let productPrice;
		props.productsListDuck.productsList.data.map((product) => {
			if (product.code === service) {
				product.prices.map((price) => {
					if (price.currency === props.currencyDuck.currency) {
						productPrice = price.price;
					}
				});
			}
		});
		return productPrice;
	};

	const getBadge = (service) => {
		let badge;
		props.productsListDuck.productsList.data.map((product) => {
			if (product.code === service) {
				badge = product.iconImage;
			}
		});
		return badge;
	};

	const getName = (service) => {
		let name;
		props.productsListDuck.productsList.data.map((product) => {
			if (product.code === service) {
				let names = product.name;
				name = getValueFromLang(names, props.languageDuck.currentLanguage);
			}
		});
		return name;
	};

	const getSubtitle = (service) => {
		let subtitle;
		props.productsListDuck.productsList.data.map((product) => {
			if (product.code === service) {
				let subtitles = product.subtitle;
				subtitle = getValueFromLang(subtitles, props.languageDuck.currentLanguage);
			}
		});
		return subtitle;
	};

	const getTarget = (service) => {
		let target;
		props.productsListDuck.productsList.data.map((product) => {
			if (product.code === service) {
				target = product.target;
			}
		});
		return target;
	};

	const handlePromoChange = async (value) => {
		let selectedValue = value;
		if (value !== 'promocode') {
			selectedValue = value - 1;
		}
		setState({
			...state,
			promoSelected: value,
			promoLoading: true,
		});
		if (selectedValue !== 'promocode') {
			let promoData = {
				promotionCode: state.promo[selectedValue].promotionCode,
				currency: props.currencyDuck.currency,
				items: props.servicesDuck.services.map((service) => {
					let data = [];
					for (let i = 0; i < service.value; i++) {
						data.push({});
					}
					let productId;
					props.productsListDuck.productsList.data.map((product) => {
						if (product.code === service.name) {
							productId = product.id;
						}
					});
					return {
						productId: productId,
						productCode: service.name,
						quantity: service.value,
						data: data,
					};
				}),
			};

			let result = await calculatePromotion(promoData);
			if (result) {
				dispatch(setDiscount({ code: state.promo[selectedValue].promotionCode, amount: result.finalPrice }));
			}
		}
		setState({
			...state,
			promoLoading: false,
			promoSelected: value,
		});
	};

	const deletePromo = () => {
		dispatch(setDiscount({}));
		setState({
			...state,
			promoSelected: 0,
			promoCodeLoading: false,
			promoCodeValid: null,
		});
	};

	const menu = () => {
		return (
			<Select
				defaultValue={0}
				suffixIcon={() => {
					if (state.promoSelected === 0) {
						return <Image src={Arrow} alt={'arrow'} preview={false} />;
					}
				}}
				dropdownClassName={'checkout-services-promo-dropdown'}
				onChange={handlePromoChange}
				value={state.promoSelected}
			>
				<Option value={0} style={{ display: 'none' }}>
					<div
						className={'checkout-services-promo-text'}
						style={state.promoLoading ? { color: 'var(--main-gray)' } : null}
					>
						{t('checkout.promo')}
					</div>
				</Option>
				{state.promo.length > 0 &&
					state.promo.map((promo, i) => {
						return (
							<Option key={i + 1} value={i + 1}>
								<Title
									className={'checkout-services-promo-title'}
									style={state.promoLoading ? { color: 'var(--main-gray)' } : null}
								>
									{promo.promotionDescription}
								</Title>
								<Text className={'checkout-services-promo-description'}>{promo.promotionMessage}</Text>
							</Option>
						);
					})}
				<Option value={'promocode'} className={'checkout-services-promo-code-option'}>
					<Title className={'checkout-services-promo-title'}>{t('profile.promo.discountCode')}</Title>
					<Text className={'checkout-services-promo-description'}>{t('profile.promo.insertDiscountCode')}</Text>
				</Option>
			</Select>
		);
	};

	const getSubmitButtonData = (data) => {
		let label;
		let className;

		switch (state.promoCodeValid) {
			case null:
				label = t('general.confirm');
				className = 'checkout-services-promo-input-submit';
				break;
			case false:
				label = t('general.notValid');
				className = 'checkout-services-promo-input-submit-error';
				break;
			case true:
				label = t('general.confirmed');
				className = 'checkout-services-promo-input-submit-confirmed';
				break;
			default:
				label = t('general.confirm');
				className = 'checkout-services-promo-input-submit';
				break;
		}
		if (data === 'label') {
			return <span>{label}</span>;
		} else {
			return className;
		}
	};

	const removePromoCodeError = () => {
		if (state.promoCodeValid === false) {
			setState({
				...state,
				promoCodeValid: null,
			});
		}
	};

	return (
		<Col>
			<Col className={'checkout-services-page-container'}>
				{loading && (
					<div className={'loading-container'}>
						<Image preview={false} src={logo} className={'loading-page-notext-logo'} />
					</div>
				)}
				<Col className={'checkout-services-promo-services-container'}>
					<div className={'checkout-services-promo-services-card-container'}>
						{props.servicesDuck.services.map((service, index) => {
							return (
								<div key={index}>
									<CheckoutServiceCard
										cardCode={service.name}
										cardTitle={getName(service.name)}
										cardSubtitle={getSubtitle(service.name)}
										priceForItem={formatValue(getPrice(service.name))}
										target={getTarget(service.name)}
										badge={getBadge(service.name)}
										noDescription
										currency={props.currencyDuck.currency === 'EUR' ? '€' : '$'}
									/>
								</div>
							);
						})}
					</div>

					<Row className={'checkout-services-promo-container'}>
						<Row className={'checkout-services-promo-icon-container'}>
							<Image src={Info} alt={'info'} preview={false} />
							{menu()}
							{state.promoSelected !== 0 && (
								<div className={'checkout-services-promo-delete'}>
									<Image src={Bucket} alt={'delete'} preview={false} onClick={deletePromo} />
								</div>
							)}
						</Row>
						{state.promoSelected === 'promocode' && (
							<Row className={'checkout-services-promo-input-container'}>
								<Input
									id={'promoCode'}
									value={formikPromoCode.values.promoCode}
									onFocus={removePromoCodeError}
									onChange={formikPromoCode.handleChange}
									placeholder={'12345'}
								/>
								<Button
									loading={state.promoCodeLoading}
									className={getSubmitButtonData('className')}
									onClick={state.promoCodeValid === null ? formikPromoCode.submitForm : null}
								>
									{getSubmitButtonData('label')}
								</Button>
							</Row>
						)}
					</Row>
				</Col>

				<CheckoutServicesBox />
			</Col>
			<FooterCheckout />
		</Col>
	);
};

const mapStateToProps = (state) => ({
	servicesDuck: state.servicesDuck,
	productsListDuck: state.productsListDuck,
	currencyDuck: state.currencyDuck,
	languageDuck: state.languageDuck,
	userInfoDuck: state.userInfoDuck,
});

export default connect(mapStateToProps)(CheckoutServices);
