import React, { useEffect, useState } from "react";

// STYLES
import "./ForgotPassword.css";

// COMPONENTS
import CustomButton from "../../components/functional_components/customButton/CustomButton";
import SEO from "../../components/functional_components/SEO/SEO";

// REDUX
import { confirmResetPsw } from "../../redux/actions/actions";
import { useDispatch } from "react-redux";

import { passwordValidation } from "../../utils/properties";
import { Col, Row, Typography, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../utils/properties";

const ResetPassword = () => {

  const [state, setState] = useState({
    validPassword: true
  })

  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const location = useLocation();
  const urlParams = Object.fromEntries([...new URLSearchParams(location.search)]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!urlParams.token) {
      navigate(`${ENVIRONMENT.ROUTING.SIGNUP_URL}/forgot-password`);
    }
  }, [])

  const formikResetPsw = useFormik({
    initialValues: {
      newPassword: ''
    },
    onSubmit: (values) => {
      resetPsw(values);
    }
  });

  const resetPsw = async (data) => {
    if (!passwordValidation(data.newPassword)) {
      return (
        setState({
          ...state,
          validPassword: false
        })
      )
    }
    let resetPasswordJSON = {};
    resetPasswordJSON.token = urlParams.token;
    resetPasswordJSON.newPassword = data.newPassword;

    await confirmResetPsw(dispatch, resetPasswordJSON);

    navigate(`${ENVIRONMENT.ROUTING.BASE_URL}`);
  }

  const handlePasswordInputFocus = () => {
    setState({
      ...state,
      validPassword: true
    })
  }

  return (
    <Col className={"forgot-psw-container"}>
      <SEO
        page={"ResetPsw"}
        url={"forgot-password/reset-password"}
      />

      <Title className={"forgot-psw-title"}>{t("forgotPassword.resetPassword")}</Title>
      <Text className={"forgot-psw-subtitle"}>{t("forgotPassword.resetPasswordSubtitle")}</Text>

      <Row className={"reset-psw-input-container"}>
        <label className='login-label'>{t('forgotPassword.newPassword')}</label>
        <Input.Password
          id="newPassword"
          name="newPassword"
          placeholder={t('general.passwordPlaceholder')}
          onChange={formikResetPsw.handleChange}
          onBlur={formikResetPsw.handleBlur}
          value={formikResetPsw.values.newPassword}
          className={state.validPassword ? 'login-input-box' : 'login-input-box invalid-field'}
          onFocus={handlePasswordInputFocus}
        />
        {
          !state.validPassword &&
          <Text className={'invalid-field-message'}>{t('general.invalidPassword')}</Text>
        }
      </Row>

      <Row className={"forgot-password-submit-button-container"}>
        <CustomButton
          content={t("general.continue")}
          clickCallback={formikResetPsw.submitForm}
        />
      </Row>
    </Col>
  )
}

export default ResetPassword