const SET_LOUNGE = 'SET_LOUNGE'
const INIT_LOUNGE = 'INIT_LOUNGE'

export function setLounge(value) {
  return {
    type: SET_LOUNGE,
    payload: {
      lounge: value
    }
  };
}

export function initLounge() {
  return {
    type: INIT_LOUNGE,
    payload: {
      lounge: {}
    }
  }
}

const INIT_STATE = {
  lounge: {}
}

export default function loungeDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_LOUNGE:
      newState.lounge = action.payload.lounge;
      break;
    case INIT_LOUNGE:
      newState.lounge = {};
      break;
    default:
      break;
  }
  return newState;
}