import React from 'react';

// STYLES
import './FlightSearchInput.css';

import { Input, Typography, Image, Select, Row, Col } from "antd";

const FlightSearchInput = (props) => {

  const { Text } = Typography;
  const { Option } = Select;

  const handleFlightNumber = (e) => {
    if(e.target.value.length > 4) {
      return
    }
    props.onChange(e);
  }

  const checkNumber = (e) => {
    if (e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57)
    {
        e.preventDefault();
    }
  }

  return (
    <div className={props.id === "airline" ? "home-flight-search-input-container home-flight-search-airline-input-container" : "home-flight-search-input-container"}>
      {
        props.id === "flightNumber" &&
        <>
        <Input
          id={props.id}
          name={props.id}
          onKeyPress={checkNumber}
          onChange={handleFlightNumber}
          value={props.value}
          placeholder={props.placeholder}
          className={'home-flight-search-input home-flight-search-input-flight-number'}
          autoComplete={"off"}
          type={"number"}
        />
        <Text className={'home-flight-search-input-hashtag'}>#</Text>
        </>
      }
      {
        props.id === "airline" &&
        <Select
          showSearch
          value={props.value}
          className={'home-flight-search-input home-flight-search-input-flight-number'}
          dropdownClassName={'home-flight-search-input-dropdown'}
          bordered={false}
          onChange={props.onChange}
          placeholder={props.placeholder}
          filterOption={(input, option) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            props.airlinesList &&
            props.airlinesList.map((airline, index) => {
              if (airline.iata) {
                return (
                  <Option
                    key={index}
                    value={airline.iata}
                    search={airline.searchValue}
                    className={'home-flight-search-input-option'}
                  >
                    <Row className={'home-flight-search-input-option-content-container'}>
                      <Col className={'home-flight-search-input-option-content-name'}>
                        <div>{airline.name}</div>
                      </Col>
                      <div className={'home-flight-search-input-option-content-iata'}>{airline.iata}</div>
                    </Row>
                  </Option>
                )
              }
            })
          }
        </Select>
      }
      {
        props.id !== "flightNumber" && props.id !== "airline" &&
        <Select
          showSearch
          value={props.value}
          className={'home-flight-search-input'}
          dropdownClassName={'home-flight-search-input-dropdown'}
          bordered={false}
          onChange={props.onChange}
          placeholder={props.placeholder}
          filterOption={(input, option) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            props.airportsList &&
            props.airportsList.map((airport, index) => {
              if (airport.iata) {
                return (
                  <Option
                    key={index}
                    value={airport.iata}
                    search={airport.searchValue}
                    className={'home-flight-search-input-option'}
                  >
                    <Row className={'home-flight-search-input-option-content-container'}>
                      <Col className={'home-flight-search-input-option-content-name'}>
                        <div>{airport.name}</div>
                        <div>{airport.city}</div>
                      </Col>
                      <div className={'home-flight-search-input-option-content-iata'}>{airport.iata}</div>
                    </Row>
                  </Option>
                )
              }
            })
          }
        </Select>
      }
      <div className={'home-flight-search-input-icon'}>
        <Image
          src={props.icon}
          alt={props.iconAlt}
          preview={false}
        />
      </div>

      {
        !props.hideText &&
        <Text className={"home-flight-search-input-label"}>{props.id}</Text>
      }
    </div>
  )
}

export default FlightSearchInput;