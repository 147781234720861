const SET_NEWSLETTERDATA = 'SET_NEWSLETTERDATA'
const INIT_NEWSLETTERDATA = 'INIT_NEWSLETTERDATA'

export function setNewsletterData(value) {
  return {
    type: SET_NEWSLETTERDATA,
    payload: {
      newsletterData: value
    }
  };
}

export function initNewsletterData() {
  return {
    type: INIT_NEWSLETTERDATA,
    payload: {
      newsletterData: {}
    }
  }
}

const INIT_STATE = {
  newsletterData: {}
}

export default function newsletterDataDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case SET_NEWSLETTERDATA:
      newState.newsletterData = action.payload.newsletterData;
      break;
    case INIT_NEWSLETTERDATA:
      newState.newsletterData = {};
      break;
    default:
      break;
  }
  return newState;
}