import React, { useEffect, useState } from "react";

// STYLES
import './ProfileLayout.css';

// COMPONENTS
import ProfileHeader from "../../components/functional_components/profileHeader/ProfileHeader";
import MobileNavbar from "../../components/hooks_components/mobileNavbar/MobileNavbar";
import HeaderHome from "../../components/functional_components/headerHome/HeaderHome";
import FooterHome from "../../components/functional_components/footerHome/FooterHome";
import Loading from "../../screens/Loading/Loading";

// REDUX
import { getProductsList, getUserData } from "../../redux/actions/actions";
import { useDispatch, connect } from "react-redux";

import { Layout } from "antd";
import { Outlet } from "react-router";
import B2bFooter from "../../components/functional_components/b2bFooter/B2bFooter";
import { isEmpty } from "lodash";

const ProfileLayout = (props) => {

  const [state, setState] = useState({
    loaded: false
  })

  const { Header, Content, Footer } = Layout;
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    if (Object.keys(props.productsListDuck.productsList).length === 0) {
      await getProductsList(dispatch);
    }

    if (Object.keys(props.userDataDuck.userData).length === 0) {
      await getUserData(dispatch);
    }

    setState({
      ...state,
      loaded: true
    })
  }

  const usertype = () => {
		let userType = "B2C";
		if (props.userInfoDuck.userInfo && !isEmpty(props.userInfoDuck.userInfo)) {
			if (props.userInfoDuck.userInfo.type !== "B2C") {
				userType = "B2B";
			}
		}
		return userType;
	}


  return (
    <Layout>
      {
        state.loaded &&
        <>
          <Header className={'profile-layout-header-container'}>
            <div className={'profile-layout-mobile-header-container'}>
              <ProfileHeader />
            </div>
            <div className={'profile-layout-desktop-header-container'}>
              <HeaderHome />
              <div className={"profile-layout-desktop-header-mask"} />
            </div>
          </Header>

          <Content className={'profile-layout-content-container'}>
            <Outlet />
          </Content>

          <MobileNavbar />

          <Footer className={"profile-layout-footer-container"}>
            { usertype() === "B2C" ? <FooterHome /> : <B2bFooter max100 />}            
          </Footer>
        </>
      }
      {
        !state.loaded &&
        <Loading />
      }
    </Layout>
  )
}

const mapStateToProps = state => ({
  productsListDuck: state.productsListDuck,
  userDataDuck: state.userDataDuck,
  userInfoDuck: state.userInfoDuck
})

export default connect(mapStateToProps)(ProfileLayout)