import React, { useState } from "react";

// STYLES
import './B2bFooter.css';

// COMPONENTS
import LangModal from "../../hooks_components/langModal/LangModal";

// ASSETS
import Logo from '../../../assets/images/logo/logo-sos-travel-white.svg';
import Globe from '../../../assets/images/footer/footer-globe.svg';

import { Col, Row, Image, Typography } from "antd";
import { ENVIRONMENT } from "../../../utils/properties";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const B2bFooter = (props) => {

  const { Link, Text } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    isModalVisible: false
  })

  const showModal = () => {
    setState({
      ...state,
      isModalVisible: true
    })
  }

  const handleCancel = () => {
    setState({
      ...state,
      isModalVisible: false
    })
  }

  const langValue = () => {
    let value = '';
    switch (props.languageDuck.currentLanguage) {
      case ("IT"):
        value = "Italiano (IT)";
        break;
      case ("EN"):
        value = "English (EN)";
        break;
      case ("PT"):
        value = "Português (PT)";
        break;
      case ("ES"):
        value = "Español (ES)";
        break;
      case ("RU"):
        value = "русский (RU)";
        break;
      case ("FR"):
        value = "Français (FR)";
        break;
      default:
        value = "Language";
        break;
    }
    return value
  }

  const currValue = () => {
    let curr = '';
    switch (props.currencyDuck.currency) {
      case ("EUR"):
        curr = "€ - EUR";
        break;
      case ("USD"):
        curr = "$ - USD";
        break;
      default:
        curr = "€ - EUR";
        break;
    }
    return curr;
  }

  return (
    <Col className={props.max100 ? "b2b-footer-checkout-container-100 centered" : "b2b-footer-checkout-container centered"}>
      <LangModal
        visible={state.isModalVisible}
        handleCancel={handleCancel}
      />

      <Col className={"b2b-footer-checkout-content-container"}>
        <Col className={"b2b-footer-checkout-links-container"}>
          <Link href={ENVIRONMENT.ROUTING.B2B_HOME_URL} className={'footer-description-logo'}>
            <Image
              src={Logo}
              alt={'sostravel logo'}
              preview={false}
            />
          </Link>

          <Link to={"#"} onClick={() => {window.location = "mailto:assistenza@sostravel.com"}}><Text>assistenza@sostravel.com</Text></Link>

          <Link to={"#"} onClick={() => navigate(`${ENVIRONMENT.ROUTING.BASE_URL}faqs`)}><Text>FAQ</Text></Link>
        </Col>

        <Row className={'footer-separator-line'} />

        <Row className={"b2b-footer-checkout-rights-container"}>
          <Text className={'footer-reserved-rights-text'}>{t('footer.reservedRights')}</Text>

          <Row className={'footer-bottom-buttons-lang'} onClick={showModal}>
            <Image
              src={Globe}
              alt={'globe'}
              preview={false}
            />
            <Text className={'footer-bottom-buttons-lang-text'}>{langValue()}</Text>
            <Text>{currValue()}</Text>
          </Row>
        </Row>

      </Col>

    </Col>
  )
}

const mapStateToProps = state => ({
  languageDuck: state.languageDuck,
  currencyDuck: state.currencyDuck
})

export default connect(mapStateToProps)(B2bFooter)