/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

// STYLES
import './CheckoutServicesBox.css';

// COMPONENTS
import CustomButton from '../customButton/CustomButton';
import VipInputBox from '../vipInputBox/VipInputBox';
import CheckoutUserData from '../checkoutUserData/CheckoutUserData';
import ReceiptForm from '../receiptForm/ReceiptForm';

// ASSETS
import Profile from '../../../assets/images/checkout/profile-icon.svg';
import Arrow from '../../../assets/images/checkout/down-arrow.svg';
import Info from '../../../assets/images/checkout/info-promo.svg';
import Bucket from '../../../assets/images/checkout/delete-item-icon.svg';

// REDUX
import { connect, useDispatch } from 'react-redux';
import { setServices, initServices } from '../../../redux/ducks/Services';
import { doUpdateData, getUserData, doPayment } from '../../../redux/actions/actions';

import { Col, Image, Row, Typography, Input, DatePicker, Checkbox, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ENVIRONMENT } from '../../../utils/properties';
import { useFormik } from 'formik';
import { formatValue } from '../../../utils/utilities';
import moment from 'moment';
import { get } from 'lodash';
import { setLocale } from '../../../utils/utilities';

const CheckoutServicesBox = (props) => {
	const [state, setState] = useState({
		profileVisible: false,
		vipUsers: 1,
		usersData: [],
		missingDataError: false,
		wrongStartDateError: false,
		b2bUserFlightDate: '',
		b2bMissingDataError: false,
		missingUserDataError: false,
		missingReceiptDataError: false,
		receipt: false,
		drtravel: false,
	});

	const { t } = useTranslation();
	const navigate = useNavigate();
	const { Text } = Typography;
	const dispatch = useDispatch();

	useEffect(() => {
		let vipUsersNumber = 0;
		let drtravel = false;
		props.servicesDuck.services.map((service) => {
			if (service.name === 'VIPLOUNGE') {
				vipUsersNumber = service.value;
			}
			if (service.name === 'TLDRST') {
				drtravel = true;
			}
		});

		let newUsersData = state.usersData;
		if (newUsersData.length === 0 && vipUsersNumber > 0) {
			for (let i = 0; i < vipUsersNumber; i++) {
				newUsersData.push({
					name: '',
					surname: '',
					startDate: '',
				});
			}
		} else {
			if (newUsersData.length > vipUsersNumber) {
				newUsersData.pop();
			} else if (newUsersData.length < vipUsersNumber) {
				newUsersData.push({
					name: '',
					surname: '',
					startDate: '',
				});
			}
		}

		setState({
			...state,
			vipUsers: vipUsersNumber,
			usersData: newUsersData,
			drtravel: drtravel,
		});
	}, [props]);

	const formikUserRegistration = useFormik({
		initialValues: {
			name: '',
			surname: '',
			flightNumber: '',
			flightDate: state.b2bUserFlightDate,
		},
		onSubmit: (values) => {
			handleClickConfirm(values);
		},
	});

	const formikUserData = useFormik({
		initialValues: {
			name: get(props.userDataDuck.userData, 'data.name', ''),
			surname: get(props.userDataDuck.userData, 'data.surname', ''),
			email: get(props.userDataDuck.userData, 'data.email', ''),
			prefix: get(props.userDataDuck.userData, 'data.prefixPhone', 39),
			prefixPhoneCountry: get(props.userDataDuck.userData, 'data.prefixPhoneCountry', 'IT'),
			phone: get(props.userDataDuck.userData, 'data.phone', ''),
			country: get(props.userDataDuck.userData, 'data.userData.country', null),
			cdfOrPiva: '',
			sdi: '',
			invoice: false,
		},
		onSubmit: (values) => {
			handleClickConfirm(values);
		},
	});

	const onFlightDateChange = (dateString) => {
		setState({
			...state,
			b2bUserFlightDate: moment(dateString).format('DD/MM/YYYY'),
		});
		formikUserRegistration.values.flightDate = moment(dateString).format('YYYY/MM/DD');
	};

	const handleClickConfirm = async (values) => {
		if (!props.b2b) {
			if (state.receipt && values.cdfOrPiva === '') {
				return setState({
					...state,
					missingReceiptDataError: true,
				});
			}
			let missingData = false;
			let missingUserData = false;

			if (!values.name || !values.surname || !values.email || !values.country) {
				missingUserData = true;
			}

			if (missingUserData) {
				return setState({
					...state,
					missingUserDataError: true,
				});
			}

			if (state.usersData.length > 0) {
				for (let i = 0; i < state.usersData.length; i++) {
					let isEmpty = Object.values(state.usersData[i]).some((x) => x === null || x === '');
					if (isEmpty) {
						missingData = true;
					}
				}
				if (missingData) {
					return setState({
						...state,
						missingDataError: true,
					});
				} else {
					let servicesData = props.servicesDuck.services;
					servicesData.map((service) => {
						if (service.name !== 'LLC' && service.name !== 'VIPLOUNGE') {
							service.data = [
								{
									date: `${moment(state.b2bUserFlightDate).format('YYYY-MM-DDTHH:mm Z')} CET`,
								},
							];
						}
						if (service.name === 'VIPLOUNGE') {
							service.data = state.usersData;
							service.data.map((data) => {
								data.startDate = moment(data.startDate).format();
								if (data.startDate < moment(new Date().setDate(new Date().getDate() - 1)).format()) {
									return setState({
										...state,
										wrongStartDateError: true,
									});
								}
							});
						}
					});
					dispatch(setServices(servicesData));
				}
			}

			let updateData = {
				country: values.country,
				name: values.name,
				surname: values.surname,
				prefixPhone: values.prefix ? values.prefix : 39,
				prefixPhoneCountry: values.prefixPhoneCountry ? values.prefixPhoneCountry : 'IT',
				phone: values.phone,
				type: props.userInfoDuck.userInfo.type,
				id: props.userInfoDuck.userInfo.id,
				email: values.email,
				username: props.userDataDuck.userData.data.username,
				source: 'SOSTRAVEL',
				gender: props.userDataDuck.userData.data.userData.gender,
				birthDate: props.userDataDuck.userData.data.userData.birthDate,
				city: props.userDataDuck.userData.data.userData.city,
				address: props.userDataDuck.userData.data.userData.address,
				zipCode: props.userDataDuck.userData.data.userData.zipCode,
				privacyPolicy: props.userDataDuck.userData.data.userLegal.privacyPolicy,
				marketingPolicy: props.userDataDuck.userData.data.userLegal.marketingPolicy,
				thirtyPartPolicy: props.userDataDuck.userData.data.userLegal.thirtyPartPolicy,
			};

			await doUpdateData(updateData);
			await getUserData(dispatch);

			//calcolo totale
			let result = 0;
			let servicePrice;
			props.servicesDuck.services.map((service) => {
				service.prices.map((price) => {
					if (price.currency === props.currencyDuck.currency) {
						servicePrice = price.price;
					}
				});
				result = result + service.value * servicePrice;
			});

			//controllo totale con discount
			//Codice da sistemare: fatto copia incolla per porre rimedio
			// a un errore di chi ha sviluppato.
			//In caso di amuount zero non deve chiedere carta di credito
			if (props.discountDuck.discount.code !== null && props.discountDuck.discount.amount === 0) {
				let orders = [];

				const getServiceId = (service) => {
					let id;
					props.productsListDuck.productsList.data.map((product) => {
						if (product.code === service.name) {
							id = product.id;
						}
					});
					return id;
				};

				let servicesData = props.servicesDuck.services;
				const ordersPush = () => {
					servicesData.map((service) => {
						let order = {
							productCode: service.name,
							productId: getServiceId(service),
							quantity: service.value,
						};

						if (service.name !== 'LLC') {
							order = {
								productCode: service.name,
								productId: getServiceId(service),
								quantity: service.value,
								data: service.data,
							};
						}

						orders.push(order);
					});
				};

				ordersPush();

				let order = {
					currency: props.currencyDuck.currency,
					items: orders,
					paymentNonce: 'free',
					customerName: props.userDataDuck.userData.data.name ? props.userDataDuck.userData.data.name : null,
					customerSurname: props.userDataDuck.userData.data.surname ? props.userDataDuck.userData.data.surname : null,
					invoice: false,
					cdfOrPiva: '',
					sdi: '',
				};

				if (props.discountDuck.discount.code) {
					order.promotionCode = props.discountDuck.discount.code;
				}

				await doPayment(order);

				let services = [];
				servicesData.map((service) => {
					services.push(service.name);
				});
				dispatch(initServices());
				navigate(`${ENVIRONMENT.ROUTING.BASE_URL}thank-you`, { state: { services: services } });
			} else {
				navigate(`${ENVIRONMENT.ROUTING.BASE_URL}payment`, {
					state: { userData: values, discount: props.discountDuck.discount },
				});
			}
		} else {
			let missingData = false;
			let testValues = [];
			testValues.name = values.name;
			testValues.surname = values.surname;

			if (state.drtravel) {
				testValues.flightNumber = values.flightNumber;
				testValues.flightDate = values.flightDate;
			}

			let isEmpty = Object.values(testValues).some((x) => x === null || x === '');
			if (isEmpty) {
				missingData = true;
			}

			if (missingData) {
				return setState({
					...state,
					b2bMissingDataError: true,
				});
			}
			let servicesData = props.servicesDuck.services;
			if (state.usersData.length > 0) {
				for (let i = 0; i < state.usersData.length; i++) {
					let isEmpty = Object.values(state.usersData[i]).some((x) => x === null || x === '');
					if (isEmpty) {
						missingData = true;
					}
				}
				if (missingData) {
					return setState({
						...state,
						missingDataError: true,
					});
				} else {
					servicesData.map((service) => {
						if (service.name === 'VIPLOUNGE') {
							service.data = state.usersData;
							service.data.map((data) => {
								data.startDate = moment(data.startDate).format();
								if (data.startDate < moment(new Date().setDate(new Date().getDate() - 1)).format()) {
									return setState({
										...state,
										wrongStartDateError: true,
									});
								}
							});
						}
					});
				}
			}
			props.servicesDuck.services.map((service) => {
				if (service.name !== 'LLC' && service.name !== 'VIPLOUNGE') {
					service.data = [
						{
							date: `${moment(values.flightDate).format('YYYY-MM-DDTHH:mm Z')} CET`,
						},
					];
				}
			});
			dispatch(setServices(servicesData));
			navigate(`${ENVIRONMENT.ROUTING.BASE_URL}B2B/payment`, {
				state: { data: values },
			});
		}
	};

	const removeErrorMessage = () => {
		setState({
			...state,
			b2bMissingDataError: false,
			missingUserDataError: false,
			missingReceiptDataError: false,
		});
	};

	const handleClickProfile = () => {
		setState({
			...state,
			profileVisible: !state.profileVisible,
		});
	};

	const handleChange = (index) => (e) => {
		let newUsersData = state.usersData;
		for (let i = 0; i < state.vipUsers; i++) {
			if (index === i) {
				newUsersData[i][e.target.name] = e.target.value;
			}
		}

		setState({
			...state,
			usersData: newUsersData,
			missingDataError: false,
			wrongStartDateError: false,
		});
	};

	const vipUserInput = () => {
		let result = [];
		for (let i = 0; i < state.vipUsers; i++) {
			result.push(
				<div key={i}>
					<VipInputBox id={i} handleChange={handleChange(i)} />
				</div>
			);
		}
		return result;
	};

	const getPrice = (service) => {
		let productPrice;
		props.productsListDuck.productsList.data.map((product) => {
			if (product.code === service.name) {
				product.prices.map((price) => {
					if (price.currency === props.currencyDuck.currency) {
						productPrice = price.price;
					}
				});
			}
		});
		return (
			<Text>
				{props.currencyDuck.currency === 'EUR' ? '€' : '$'} {formatValue(productPrice)}
			</Text>
		);
	};

	const totAmount = () => {
		let result = 0;
		let servicePrice;
		props.servicesDuck.services.map((service) => {
			service.prices.map((price) => {
				if (price.currency === props.currencyDuck.currency) {
					servicePrice = price.price;
				}
			});
			result = result + service.value * servicePrice;
		});
		if (!props.discountDuck.discount.code) {
			return (
				<Text>
					{props.currencyDuck.currency === 'EUR' ? '€' : '$'} {formatValue(result)}
				</Text>
			);
		}

		return (
			<Row>
				<Text className={'checkout-services-box-discount'}>
					{props.currencyDuck.currency === 'EUR' ? '€' : '$'} {formatValue(result)}
				</Text>
				<Text>
					{props.currencyDuck.currency === 'EUR' ? '€' : '$'} {formatValue(props.discountDuck.discount.amount)}
				</Text>
			</Row>
		);
	};

	const getUserName = () => {
		let userData = props.userDataDuck.userData.data;
		if (userData.name && userData.surname && userData.email && userData.userData.country) {
			return (
				<span>
					{userData.name} {userData.surname}
				</span>
			);
		}
		return <span>{t('checkout.completeProfile')}</span>;
	};

	const prefixHandleChange = (value, prefixPhoneCountry) => {
		formikUserData.values.prefix = value;
		formikUserData.values.prefixPhoneCountry = prefixPhoneCountry;
	};

	const countryHandleChange = (value) => {
		formikUserData.values.country = value;
	};

	const deleteItem = (service) => () => {
		let cart = props.servicesDuck.services;
		cart.map((item, i) => {
			if (item.name === service.name) {
				cart.splice(i, 1);
			}
		});
		dispatch(setServices(cart));
		if (cart.length === 0) {
			navigate(props.b2b ? `${ENVIRONMENT.ROUTING.BASE_URL}B2B/home` : `${ENVIRONMENT.ROUTING.BASE_URL}`);
		}
	};

	const disabledDate = (current) => {
		return current && current < moment().startOf('day');
	};

	const receiptCheckbox = (e) => {
		formikUserData.values.invoice = e.target.checked;
		setState({
			...state,
			receipt: e.target.checked,
		});
	};

	return (
		<Col className={'checkout-services-box-container'}>
			<Row className={'checkout-services-box-services-icons-container'}>
				{props.servicesDuck.services.map((service) => {
					return props.productsListDuck.productsList.data.map((product, index) => {
						if (service.name === product.code) {
							return (
								<div className={'checkout-services-box-services-icon'} key={index}>
									<Image src={product.iconImage} alt={'logo'} preview={false} />
								</div>
							);
						}
					});
				})}
			</Row>

			<div className={'checkout-services-box-horizontal-line'} />

			<Row
				className={'checkout-services-box-profile-user-container'}
				onClick={props.b2b ? null : handleClickProfile}
				style={!props.b2b ? { cursor: 'pointer' } : null}
			>
				<div className={'checkout-services-box-profile-icon-container'}>
					<Image src={Profile} alt={'profile'} preview={false} />
				</div>
				{props.b2b && (
					<>
						<Text className={'checkout-services-box-profile-user-name-text'}>{t('checkout.registerCustomer')}</Text>
						<Col className={'checkout-services-box-user-data-container'}>
							<Col>
								<label>{t('general.name')}</label>
								<Input
									id={'name'}
									placeholder={t('general.name')}
									value={formikUserRegistration.name}
									onChange={formikUserRegistration.handleChange}
									onFocus={removeErrorMessage}
									autoComplete={'off'}
								/>
							</Col>
							<Col>
								<label>{t('general.surname')}</label>
								<Input
									id={'surname'}
									placeholder={t('general.surname')}
									value={formikUserRegistration.surname}
									onChange={formikUserRegistration.handleChange}
									onFocus={removeErrorMessage}
									autoComplete={'off'}
								/>
							</Col>
						</Col>
						{state.drtravel && (
							<Col className="w-100">
								<Row className={'checkout-services-box-ticket-number-text-container'}>
									<Text>{t('checkout.ticketNumber')}</Text>
									<Image src={Info} alt={'info'} preview={false} style={{ filter: 'brightness(0)' }} />
								</Row>
								<Col className={'checkout-services-box-ticket-number-data-container'}>
									<Input
										id={'flightNumber'}
										placeholder={'#12345'}
										value={formikUserRegistration.flightNumber}
										onChange={formikUserRegistration.handleChange}
										onFocus={removeErrorMessage}
										autoComplete={'off'}
									/>
									<ConfigProvider locale={setLocale(props.languageDuck.currentLanguage)}>
										<DatePicker
											id={'flightDate'}
											value={formikUserRegistration.flightDate}
											onChange={onFlightDateChange}
											onFocus={removeErrorMessage}
											disabledDate={disabledDate}
											format={'DD/MM/YYYY'}
										/>
									</ConfigProvider>
								</Col>
							</Col>
						)}
					</>
				)}
				{!props.b2b && (
					<>
						<Text className={'checkout-services-box-profile-user-name-text'}>{getUserName()}</Text>
						<Image src={Arrow} alt={'arrow'} preview={false} />
					</>
				)}
			</Row>

			{state.profileVisible && (
				<CheckoutUserData
					handleChange={formikUserData.handleChange}
					nameValue={formikUserData.name}
					surnameValue={formikUserData.surname}
					emailValue={formikUserData.email}
					prefixValue={formikUserData.prefix}
					phoneValue={formikUserData.phone}
					countryValue={formikUserData.country}
					prefixHandleChange={prefixHandleChange}
					countryHandleChange={countryHandleChange}
					removeErrorMessage={removeErrorMessage}
					userData={props.userDataDuck.userData}
				/>
			)}
			{!props.b2b && state.drtravel && (
				<Col className={'checkout-services-box-ticket-number-data-container mb-10'}>
					<div className={'checkout-services-box-ticket-number-data-drtravel'}>Dr.Travel</div>
					<ConfigProvider locale={setLocale(props.languageDuck.currentLanguage)}>
						<DatePicker
							onChange={onFlightDateChange}
							onFocus={removeErrorMessage}
							disabledDate={disabledDate}
							format={'DD/MM/YYYY'}
						/>
					</ConfigProvider>
				</Col>
			)}
			{state.vipUsers > 0 && (
				<Col className={'checkout-services-box-vip-container'}>
					<Row className={'checkout-services-box-vip-preview-container'}>
						{props.productsListDuck.productsList.data.map((product, index) => {
							if (product.code === 'VIPLOUNGE') {
								return (
									<div className={'checkout-services-box-profile-icon-container'} key={index}>
										<Image src={product.iconImage} alt={'vip'} preview={false} />
									</div>
								);
							}
						})}
						<Text className={'checkout-services-box-profile-user-name-text'}>{t('checkout.addVipPass')}</Text>
					</Row>
					<Col className={'f-col-c'}>{vipUserInput()}</Col>
				</Col>
			)}

			{props.servicesDuck.services.map((service, index) => {
				return (
					<Row key={index} className={'checkout-services-box-vip-service-text-container'}>
						<Text>
							<Image src={Bucket} alt={'delete'} preview={false} onClick={deleteItem(service)} />
							{service.name}
						</Text>
						<Text>
							{service.value} x {getPrice(service)}
						</Text>
					</Row>
				);
			})}

			<div className={'checkout-services-box-horizontal-line'} />

			<Row className={'checkout-services-box-vip-service-tot-text-container'}>
				<Text>{t('siderMenu.tot')}</Text>
				{totAmount()}
			</Row>

			<div className={'checkout-services-box-complete-button-container'}>
				<CustomButton
					type={'primary'}
					content={t('checkout.completePayment')}
					style={{
						width: '100%',
						height: '48px',
						backgroundColor: '#FFFFFF',
						color: 'var(--main-black)',
						fontSize: '16px',
					}}
					clickCallback={props.b2b ? formikUserRegistration.submitForm : formikUserData.submitForm}
				/>
			</div>
			{state.b2bMissingDataError && (
				<Col className={'checkout-services-box-missing-data-error-message-container'}>
					<Text className={'checkout-services-box-missing-data-error-message'}>
						{t('checkout.b2bMissingDataError')}
					</Text>
				</Col>
			)}
			{state.missingDataError && (
				<Col className={'checkout-services-box-missing-data-error-message-container'}>
					<Text className={'checkout-services-box-missing-data-error-message'}>{t('checkout.missingDataError')}</Text>
				</Col>
			)}
			{state.wrongStartDateError && (
				<Col className={'checkout-services-box-missing-data-error-message-container'}>
					<Text className={'checkout-services-box-missing-data-error-message'}>{t('checkout.invalidDateError')}</Text>
				</Col>
			)}
			{state.missingUserDataError && (
				<Col className={'checkout-services-box-missing-data-error-message-container'}>
					<Text className={'checkout-services-box-missing-data-error-message'}>
						{t('checkout.missingUserDataError')}
					</Text>
				</Col>
			)}
			{state.missingReceiptDataError && (
				<Col className={'checkout-services-box-missing-data-error-message-container'}>
					<Text className={'checkout-services-box-missing-data-error-message'}>
						{t('checkout.missingReceiptDataError')}
					</Text>
				</Col>
			)}
			{!props.b2b && <Checkbox onChange={receiptCheckbox}>{t('checkout.invoiceRequest')}</Checkbox>}
			{state.receipt && (
				<ReceiptForm
					handleChange={formikUserData.handleChange}
					pivaValue={formikUserData.cdfOrPiva}
					sdiValue={formikUserData.sdi}
					removeErrorMessage={removeErrorMessage}
				/>
			)}
		</Col>
	);
};

const mapStateToProps = (state) => ({
	servicesDuck: state.servicesDuck,
	productsListDuck: state.productsListDuck,
	currencyDuck: state.currencyDuck,
	userInfoDuck: state.userInfoDuck,
	userDataDuck: state.userDataDuck,
	discountDuck: state.discountDuck,
	languageDuck: state.languageDuck,
});

export default connect(mapStateToProps)(CheckoutServicesBox);
