/* eslint-disable no-undef */
import React from "react";

// STYLES
import "./OrdersFilter.css";

// ASSETS
import filterIcon from "../../../assets/images/profile/orders-filter-icon.svg";
import downArrow from "../../../assets/images/profile/yellow-down-arrow.svg";

import {
  Row,
  Col,
  DatePicker,
  Typography,
  Image,
  Dropdown,
  Menu,
  Checkbox,
  ConfigProvider,
} from "antd";
import { useTranslation } from "react-i18next";
import { setLocale } from "../../../utils/utilities";
import moment from "moment";
import { connect } from "react-redux";

const OrdersFilter = (props) => {
  const { RangePicker } = DatePicker;
  const { Text } = Typography;
  const { t } = useTranslation();

  const orderByList = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            props.sort((s) => ({
              ...s,
              orders: s.orders
                .map((a) => a)
                .sort((a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1)),
            }));
          }}
        >
          Latest
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            props.sort((s) => ({
              ...s,
              orders: s.orders
                .map((a) => a)
                .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1)),
            }));
          }}
        >
          Oldest
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            props.sort((s) => ({
              ...s,
              orders: s.orders
                .map((a) => a)
                .sort((a, b) => a.receiptNumber - b.receiptNumber),
            }));
          }}
        >
          N° order
        </Menu.Item>
        ;
      </Menu>
    );
  };

  const filterList = (type) => {
    return (
      <Menu>
        {props[type]?.map(({ id, name }) => {
          return (
            <Menu.Item key={id}>
              <Checkbox
                onChange={({ target: { checked } }) =>
                  handleFilterChange(id, name, type, checked)
                }
              >
                {name}
              </Checkbox>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  const handleFilterChange = (id, name, type, checked) => {
    switch (type) {
      case "agencies":
        checked
          ? props.setFilters((f) => ({
              ...f,
              parterId: f.parterId ? `${f.parterId},${id}` : id,
            }))
          : props.setFilters((f) => ({
              ...f,
              parterId: f.parterId
                .toString()
                .split(",")
                .filter((u) => u !== id.toString())
                .join(","),
            }));
        break;
      case "agents":
        checked
          ? props.setFilters((f) => ({
              ...f,
              userId: f.userId ? `${f.userId},${id}` : id,
            }))
          : props.setFilters((f) => ({
              ...f,
              userId: f.userId
                .toString()
                .split(",")
                .filter((u) => u !== id.toString())
                .join(","),
            }));
        break;
      case "clients":
        checked
          ? props.setFilters((f) => ({ ...f, client: name }))
          : props.setFilters((f) => ({ ...f, client: "" }));
        break;
      case "type":
        checked
          ? props.setFilters((f) => ({
              ...f,
              productCode: f.productCode ? `${f.productCode},${id}` : id,
            }))
          : props.setFilters((f) => ({
              ...f,
              productCode: f.productCode
                .toString()
                .split(",")
                .filter((u) => u !== id.toString())
                .join(","),
            }));
        break;
      default:
        break;
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().startOf("tomorrow");
  };

  const handleRangeDate = (date) => {
    props.setFilters((f) => {
      const dateTo = moment(date[1]).add(1, "d").format("YYYY-MM-DD");
      return {
        ...f,
        dateFrom: date[0].format("YYYY-MM-DD"),
        dateTo,
      };
    });
  };

  return (
    <Row className={"profile-order-all-filters-container"}>
      {props.orderBy && (
        <Col className={"profile-order-filter-container centered"}>
          <Image src={downArrow} alt={"filter"} preview={false} />
          <Dropdown overlay={orderByList}>
            <Text className={"profile-order-filter-text"}>
              {t("profile.orders.filter.orderBy")}
            </Text>
          </Dropdown>
        </Col>
      )}

      {props.agencies && (
        <Col className={"profile-order-filter-container centered"}>
          <Image src={filterIcon} alt={"filter"} preview={false} />
          <Dropdown overlay={filterList("agencies")}>
            <Text className={"profile-order-filter-text"}>
              {t("profile.orders.filter.agency")}
            </Text>
          </Dropdown>
        </Col>
      )}

      {props.agents && (
        <Col className={"profile-order-filter-container centered"}>
          <Image src={filterIcon} alt={"filter"} preview={false} />
          <Dropdown overlay={filterList("agents")}>
            <Text className={"profile-order-filter-text"}>
              {t("profile.orders.filter.agent")}
            </Text>
          </Dropdown>
        </Col>
      )}

      {props.clients && (
        <Col className={"profile-order-filter-container centered"}>
          <Image src={filterIcon} alt={"filter"} preview={false} />
          <Dropdown overlay={filterList("clients")}>
            <Text className={"profile-order-filter-text"}>
              {t("profile.orders.filter.client")}
            </Text>
          </Dropdown>
        </Col>
      )}

      {props.date && (
        <Col className={"profile-order-range-picker-container centered"}>
          <Image src={filterIcon} alt={"filter"} preview={false} />
          <ConfigProvider
            locale={setLocale(props.languageDuck.currentLanguage)}
          >
            <RangePicker
              className={"profile-order-range-picker"}
              disabledDate={disabledDate}
              onChange={handleRangeDate}
            />
          </ConfigProvider>

          <Text className={"profile-order-rage-picker-text centered"}>
            {t("profile.orders.filter.date")}
          </Text>
        </Col>
      )}

      {props.serviceType && (
        <Col className={"profile-order-filter-container centered"}>
          <Image src={filterIcon} alt={"filter"} preview={false} />
          <Dropdown
            overlay={
              <Menu>
                {[
                  { label: "LLC", value: "LLC" },
                  { label: "Vip Lounge", value: "VIPLOUNGE" },
                  { label: "Dr.Travel", value: "TLDRST" },
                  { label: "Dr.Travel Family", value: "TLDRFM" },
                ].map(({ label, value }) => {
                  return (
                    <Menu.Item key={value}>
                      <Checkbox
                        onChange={({ target: { checked } }) =>
                          handleFilterChange(value, label, "type", checked)
                        }
                      >
                        {label}
                      </Checkbox>
                    </Menu.Item>
                  );
                })}
              </Menu>
              //
            }
          >
            <Text className={"profile-order-filter-text"}>
              {t("profile.orders.filter.serviceType")}
            </Text>
          </Dropdown>
        </Col>
      )}
    </Row>
  );
};

OrdersFilter.defaultProps = {
  orderBy: true,
};

const mapStateToProps = (state) => ({
  languageDuck: state.languageDuck,
});

export default connect(mapStateToProps)(OrdersFilter);
