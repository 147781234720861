import React from "react";

// STYLES
import '../Checkout/CheckoutServices/CheckoutServices.css';

// COMPONENTS
import CheckoutServicesBox from "../../components/functional_components/checkoutServicesBox/CheckoutServicesBox";
import CheckoutServiceCard from "../../components/functional_components/checkoutServiceCard/CheckoutServiceCard";
import B2bFooter from '../../components/functional_components/b2bFooter/B2bFooter';

// REDUX
import { connect } from "react-redux";

import { Col } from "antd";
import { formatValue, getValueFromLang } from "../../utils/utilities";

const B2B_Checkout = (props) => {

  const getPrice = (service) => {
    let productPrice;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        product.prices.map((price) => {
          if (price.currency === props.currencyDuck.currency) {
            productPrice = price.price;
          }
        });
      }
    });
    return productPrice;
  }

  const getBadge = (service) => {
    let badge;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        badge = product.iconImage
      }
    });
    return badge;
  }

  const getName = (service) => {
    let name;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        let names = product.name;
        name = getValueFromLang(names, props.languageDuck.currentLanguage)
      }
    });
    return name;
  }

  const getSubtitle = (service) => {
    let subtitle;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        let subtitles = product.subtitle;
        subtitle = getValueFromLang(subtitles, props.languageDuck.currentLanguage)
      }
    });
    return subtitle;
  }

  const getTarget = (service) => {
    let target;
    props.productsListDuck.productsList.data.map((product) => {
      if (product.code === service) {
        target = product.target;
      }
    });
    return target;
  }

  return (
    <Col>
      <Col className={"checkout-services-page-container"}>
        <Col className={"checkout-services-promo-services-container"}>
          <div className={"checkout-services-promo-services-card-container"}>
            {
              props.servicesDuck.services.map((service, index) => {
                return (
                  <div key={index}>
                    <CheckoutServiceCard
                      cardCode={service.name}
                      cardTitle={getName(service.name)}
                      cardSubtitle={getSubtitle(service.name)}
                      priceForItem={formatValue(getPrice(service.name))}
                      target={getTarget(service.name)}
                      badge={getBadge(service.name)}
                      noDescription
                      currency={props.currencyDuck.currency === "EUR" ? "€" : "$"}
                    />
                  </div>
                )
              })
            }
          </div>
        </Col>

        <CheckoutServicesBox b2b />
      </Col>
      <B2bFooter />
    </Col>
  )
}

const mapStateToProps = state => ({
  servicesDuck: state.servicesDuck,
  productsListDuck: state.productsListDuck,
  currencyDuck: state.currencyDuck,
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(B2B_Checkout)