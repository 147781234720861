const SET_SERVICES = 'SET_SERVICES'
const INIT_SERVICES = 'INIT_SERVICES'

export function setServices(value) {
  return {
    type: SET_SERVICES,
    payload: {
      services: value
    }
  };
}

export function initServices() {
  return {
    type: INIT_SERVICES,
    payload: {
      services: []
    }
  }
}

const INIT_STATE = {
  services: []
}

export default function servicesDuck(state = INIT_STATE, action) {
  let newState = Object.assign({}, state);
  const localServices = JSON.parse(localStorage.getItem('services'));
  switch (action.type) {
    case SET_SERVICES:
      newState.services = action.payload.services;
      localStorage.setItem('services', JSON.stringify(action.payload.services));
      break;
    case INIT_SERVICES:
      newState.services = [];
      localStorage.removeItem('services')
      break;
    default:
      /*
        If I'm here, the user change manually route or refresh page and now the state is empty.
      */
      newState = state;
      if (newState.services.length === 0 && localServices) {
        newState.services = localServices;
      }
      break;
  }
  return newState;
}