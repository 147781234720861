import React from "react";

// STYLES
import './GoBackButton.css';

// ASSETS
import BackButtonIcon from '../../../assets/images/searchFlight/back-button-icon.svg';

import { Col, Typography, Image, Button } from "antd";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const GoBackButton = (props) => {

  const { Text } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackButton = (to) => () => {
    navigate(to)
  }

  return (
    <Col className={'go-back-button-container'}>
      <Button
        className={'go-back-button'}
        onClick={handleBackButton(props.to)}
      >
        <Image
          src={BackButtonIcon}
          alt={'back-button-icon'}
          preview={false}
        />
      </Button>
      <Text className={'go-back-button-text'} style={{ color: props.color }}>{t("general.goBack")}</Text>
    </Col>
  )
}

GoBackButton.defaultProps = {
  color: "#FFFFFF",
  to: -1,
}

export default GoBackButton