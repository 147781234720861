import React, { useRef } from "react";

// STYLES
import './HomeServiceCarousel.css';

// COMPONENTS
import HomeServiceCarouselCard from "../homeServiceCarouselCard/HomeServiceCarouselCard";

import { Typography } from "antd";
import { getValueFromLang } from "../../../utils/utilities";

// REDUX
import { connect } from "react-redux";
import { get } from "lodash";

const HomeServiceCarousel = (props) => {

  let isDown = false;
  let startX;
  let scrollLeft;

  const { Title } = Typography;
  const ref = useRef();

  const getPrice = (prices) => {
    let productPrice;
    prices.map((price) => {
      if (price.currency === props.currencyDuck.currency) {
        productPrice = price.price;
      }
    });
    return productPrice;
  }

  const onMouseDown = e => {
    isDown = true;
    ref.current.classList.add('home-service-carousel-active');
    startX = e.pageX - ref.current.offsetLeft;
    scrollLeft = ref.current.scrollLeft;
  };

  const notScrolling = () => {
    isDown = false;
    ref.current.classList.remove('home-service-carousel-active');
  }

  const onMouseMove = e => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - startX);
    ref.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className={'home-service-carousel-container'}>
      {
        Object.keys(props.productsListDuck.productsList).length > 0 &&
        <>
          <Title className={'home-service-carousel-title'}>
            {props.title}
          </Title>
          <div
            className={'home-service-carousel'}
            onMouseDown={onMouseDown}
            onMouseUp={notScrolling}
            onMouseLeave={notScrolling}
            onMouseMove={onMouseMove}
            ref={ref}
          >
            {
              get(props.productsListDuck.productsList, "data", []).map((product, index) => {
                if (product.b2c) {
                  return (
                    <div key={index}>
                      <HomeServiceCarouselCard
                        src={product.cardImage}
                        badge={product.iconImage}
                        cardCode={product.code}
                        cardTitle={getValueFromLang(product.name, props.languageDuck.currentLanguage)}
                        cardSubtitle={getValueFromLang(product.subtitle, props.languageDuck.currentLanguage)}
                        cardDescription={getValueFromLang(product.cardDescription, props.languageDuck.currentLanguage)}
                        priceForItem={getPrice(product.prices)}
                        target={product.target}
                        currency={props.currencyDuck.currency === "EUR" ? "€" : "$"}
                      />
                    </div>
                  )
                }
              })
            }
          </div>
        </>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  productsListDuck: state.productsListDuck,
  currencyDuck: state.currencyDuck,
  languageDuck: state.languageDuck
})

export default connect(mapStateToProps)(HomeServiceCarousel)